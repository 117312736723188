import React, { useState, useEffect } from "react";
import {
  // AddToCart,
  CampaignDetailsWrapper,
  CustomTab,
  MakeDonationWrapper,
  InfoSection,
} from "./styles";
import {
  Card,
  Button,
  Row,
  Col,
  CardImg,
  CardBody,
  CardSubtitle,
  Badge,
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constants";
import Minus from "../../assets/images/1645995_delete_minus_remove_icon.svg";
import Plus from "../../assets/images/1646001_add_create_new_plus_icon.svg";
import rightArrowImg from "../../assets/images/right-arrow.svg";
import Swal from "sweetalert2";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { Share } from "../styles";
import OurCampaigns from ".";
import Loading from "../../assets/images/loading-new.gif";

export default function CampaignDetails(CustomProps: any) {
  // const [editorState, setEditorState] = useState('');
  // const rawContentState = convertToRaw(editorState.getCurrentContent());
  // const markup = draftToHtml(
  //     rawContentState,
  // );

  // const [convertedContent, setConvertedContent] = useState("");

  // const convertContentToHTML = (editorState: any) => {
  //     let currentContentAsHTML =  (editorState.getCurrentContent());
  //     setConvertedContent(currentContentAsHTML);
  // }

  // const [activeTab, setActiveTab] = useState('1');
  const [amount, setAmount] = useState("");
  const [disableDonate, setDonateDisable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  console.log(setIsLoading);

  // const toggle = (tab: any) => {
  //     if (activeTab !== tab) setActiveTab(tab);
  // }

  // const [count, setCount] = useState(0);
  // const [isVisible, setIsVisible] = useState(true);

  const [items, setItems] = useState([
    {
      category_id: "",
      image_url: "",
      name: "",
      needed_count: 0,
      count: 0,
      price: "",
    },
  ]);

  const [campaign, setCampaign] = useState({
    id: "",
    BannerImage: "",
    Title: "",
    TagLine: "",
    Description: "",
    DonorsCount: 0,
    GoalAmount: 0,
    RaisedAmount: 0,
    StartDate: "",
    EndDate: "",
    CategoryId: "",
    CategoryName: "",
    CampaignFor: "",
    YoutubeLink: "",
    GalleryImage: "",
  });

  const [daysLeft, setDaysLeft] = useState(0);

  // const decrementCount = () => {
  //     if (count > 0) setCount(count - 1);
  // };

  // const incrementCount = () => {
  //     setCount(count + 1);
  // };

  // const toggleVisibility = () => {
  //     setIsVisible(!isVisible);
  // };

  const getCampaignById = () => {
    axios
      .get(`${API_URL}/campaigns/${CustomProps.match.params.id}`)
      .then((response) => {
        setCampaign(response.data.data);
        if (response.data.data.EndDate) {
          const oneDay = 24 * 60 * 60 * 1000;
          let startDate: any = new Date();
          let endDate: any = new Date(response.data.data.EndDate);

          const diffDays = Math.round(Math.abs((startDate - endDate) / oneDay));
          setDaysLeft(diffDays);

          sessionStorage.setItem("CampaignId", response.data.data.id);
          sessionStorage.setItem("DonationFor", response.data.data.Title);
          sessionStorage.setItem("CampaignFor", response.data.data.CampaignFor);
          sessionStorage.setItem("CategoryId", response.data.data.CategoryId);
          sessionStorage.setItem("Category", response.data.data.CategoryName);
          sessionStorage.setItem("ItemQuantity", "1");

          getItemsByCategoryId(response.data.data.CategoryId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getItemsByCategoryId = (id: string) => {
    axios
      .get(
        `${API_URL}/items?filter=%7B%0A%20%20%22where%22%3A%20%7B%22category_id%22%3A%20%22${id}%22%7D%0A%7D`
      )
      .then((response) => {
        setItems(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onItemSelected = (item: any) => {
    sessionStorage.setItem("ItemName", item.name);
    sessionStorage.setItem("ItemPrice", item.price);
    sessionStorage.setItem("ItemImage", item.image_url);

    Swal.fire({
      title: "Success!",
      text: `You have selected ${item.name}. Please click on Donate Now.`,
      icon: "success",
      confirmButtonText: "OK",
    });

    if (
      Number(campaign.GoalAmount) <
      Number(campaign.RaisedAmount || 0) + Number(item.price)
    ) {
      setDonateDisable(true);
      Swal.fire({
        title: "Error!",
        text: item?.response?.data?.error?.message
          ? item?.response?.data?.error?.message
          : "Amount cannot be greater than goal amount",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      setDonateDisable(false);
      sessionStorage.setItem("amount", item.price);
      setAmount(item.price);
    }
  };

  const onAmountChange = (val: any) => {
    sessionStorage.setItem("ItemName", "");
    sessionStorage.setItem("ItemPrice", "");
    sessionStorage.setItem("ItemImage", "");

    if (
      Number(campaign.GoalAmount) <
      Number(campaign.RaisedAmount || 0) + Number(val)
    ) {
      setDonateDisable(true);
      Swal.fire({
        title: "Error!",
        text: val?.response?.data?.error?.message
          ? val?.response?.data?.error?.message
          : "Amount cannot be greater than goal amount",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      setDonateDisable(false);
      sessionStorage.setItem("amount", val);
      setAmount(val);
    }
  };

  // const donate = () => {
  //     console.log("------------------------", campaign);
  //     sessionStorage.setItem('CampaignId', campaign.id);
  //     sessionStorage.setItem('DonationFor', campaign.Title);
  //     sessionStorage.setItem('CampaignFor', campaign.CampaignFor);
  //     sessionStorage.setItem('CategoryId', campaign.CategoryId);
  //     sessionStorage.setItem('Category', campaign.CategoryName);
  //     // sessionStorage.setItem('ItemName','5000');
  //     // sessionStorage.setItem('ItemPrice','5000');
  //     // sessionStorage.setItem('ItemImage','5000');
  //     sessionStorage.setItem('ItemQuantity', '1');

  // }

  const onItemCountChange = (item: any, value: any) => {
    // console.log(value, item);

    sessionStorage.setItem("ItemName", item.name);
    sessionStorage.setItem("ItemPrice", item.price);
    sessionStorage.setItem("ItemImage", item.image_url);
    sessionStorage.setItem("ItemQuantity", value);
    sessionStorage.setItem("amount", Number(item.price) * Number(value) + "");
    setAmount(Number(item.price) * Number(value) + "");

    items.forEach((product: any) => {
      if (product.id === item.id) {
        product.count = Number(value);
      }
    });

    let itemData = items.slice();

    setItems(itemData);
  };

  const minus = (item: any) => {
    sessionStorage.setItem("ItemName", item.name);
    sessionStorage.setItem("ItemPrice", item.price);
    sessionStorage.setItem("ItemImage", item.image_url);

    items.forEach((product: any) => {
      if (product.id === item.id) {
        if (product.count > 0) {
          product.count = Number(product.count || 0) - 1 + "";
          sessionStorage.setItem("ItemQuantity", product.count);
          sessionStorage.setItem(
            "amount",
            Number(item.price) * Number(product.count) + ""
          );
          setAmount(Number(item.price) * Number(product.count) + "");
        }
      }
    });

    let itemData = items.slice();

    setItems(itemData);
  };

  const plus = (item: any) => {
    sessionStorage.setItem("ItemName", item.name);
    sessionStorage.setItem("ItemPrice", item.price);
    sessionStorage.setItem("ItemImage", item.image_url);

    items.forEach((product: any) => {
      if (product.id === item.id) {
        product.count = Number(product.count || 0) + 1 + "";
        sessionStorage.setItem("ItemQuantity", product.count);
        sessionStorage.setItem(
          "amount",
          Number(item.price) * Number(product.count) + ""
        );
        setAmount(Number(item.price) * Number(product.count) + "");
      }
    });

    let itemData = items.slice();

    setItems(itemData);
  };

  useEffect(() => {
    getCampaignById();
    // eslint-disable-next-line
  }, []);

  function formatDate(dateString: any) {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  return (
    <CampaignDetailsWrapper>
      <div className="main-content">
        <section className="border-top">
          <div className="container pb-40">
            <div className="d-flex flex-wrap">
              <div className="left-panel">
                <div className="causes bg-white">
                  <h4 className="font-20 text-uppercase title">
                    {campaign?.Title}
                  </h4>
                  {campaign?.StartDate && (
                    <section>
                      <p>
                        <b>Event Date:</b> {formatDate(campaign.StartDate)}
                      </p>
                      <p>
                        {new Date(campaign.StartDate).getTime() > Date.now() ? (
                          <span className="upcoming-label">
                            Get ready for an exciting <b>upcoming event!</b>{" "}
                            Mark your calendars and stay tuned for more details.
                          </span>
                        ) : (
                          <span className="closed-label">
                            We have successfully<b> completed this event</b>.
                          </span>
                        )}
                      </p>
                    </section>
                  )}

                  <Row className="banner-content-section">
                    <Col md="12" className="banner-wrap">
                      <div className="thumb mt-10">
                        <img
                          src={campaign?.BannerImage}
                          alt=""
                          className="w-50"
                        />
                      </div>
                      <p className="mb-20 mt-50">
                        <b>{campaign?.TagLine}</b>
                      </p>
                    </Col>
                  </Row>

                  {/* {items.length === 0 || campaign?.GoalAmount === 0 ? null : (
                    <div className="causes-details border-1px bg-white clearfix p-0 pb-0 mt-30 mb-30">
                      <CustomTab className="mt-0 ">
                        <h3 className="text-capitalize mb-10 mt-0">
                          Donate Products
                        </h3>
                        <h4 className="mb-20">
                          You can select only one item or donate net amount.
                        </h4>

                        <Row className="d-flex grids-row">
                          {isLoading ? (
                            <div className="custom-loading">
                              <img
                                className="floating col-sm-3"
                                src={Loading}
                                alt=""
                              />
                              <img
                                className="floating col-sm-3"
                                src={Loading}
                                alt=""
                              />
                              <img
                                className="floating col-sm-3"
                                src={Loading}
                                alt=""
                              />
                              <img
                                className="floating col-sm-3"
                                src={Loading}
                                alt=""
                              />
                            </div>
                          ) : (
                            <>
                              {items.map(function (item) {
                                return (
                                  <div className="col-xs-6 col-sm-3">
                                    <Col>
                                      <Card>
                                        <CardImg
                                          top
                                          width="100%"
                                          src={item.image_url}
                                          alt="Sugar Image"
                                        />
                                        <CardBody>
                                          <CardSubtitle
                                            tag="h6"
                                            className="text-danger"
                                          >
                                            {item.name}
                                          </CardSubtitle>

                                          <p>
                                            <Badge bgcolor="warning">
                                              Rs. {item.price} /-
                                            </Badge>
                                          </p>
                                          <Button
                                            className="btn-add"
                                            onClick={() => onItemSelected(item)}
                                          >
                                            Add +
                                          </Button>

                                          <div className="plus-minus-wrapper">
                                            <span
                                              className="min button mr-10"
                                              onClick={() => minus(item)}
                                            >
                                              <img src={Minus} alt="" />
                                            </span>

                                            <input
                                              type="text"
                                              name="qty"
                                              id="qty"
                                              value={item.count}
                                              onChange={(e) =>
                                                onItemCountChange(
                                                  item,
                                                  e.target.value
                                                )
                                              }
                                              className="plus-minus-custom-input"
                                            />

                                            <span
                                              className="plus button ml-10"
                                              onClick={() => plus(item)}
                                            >
                                              <img src={Plus} alt="" />
                                            </span>
                                          </div>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </Row>
                      </CustomTab>
                    </div>
                  )} */}
                </div>
                <div className="event-details mb-30">
                  <p
                    dangerouslySetInnerHTML={{ __html: campaign?.Description }}
                  ></p>
                </div>

                <section className="clients">
                  <img
                    src={campaign?.GalleryImage}
                    alt=""
                    style={{ width: "100%" }}
                  />
                </section>

                {campaign?.YoutubeLink && (
                  <div className="mt-20 youtube-video">
                    <iframe
                      width="560"
                      height="315"
                      src={campaign?.YoutubeLink}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                )}
              </div>

              {/* <div className="right-panel">
                <div className="sidebar sidebar-right mt-sm-30">
                  {campaign?.GoalAmount !== 0 && (
                    <>
                      <InfoSection isFullWidth={true}>
                        <div>
                          <li className="font-weight-400 text-black-333 pr-0">
                            Days Left:{" "}
                            <p className="text-theme-colored font-weight-700">
                              {daysLeft}
                            </p>
                          </li>
                          <li className="font-weight-400 text-black-333 pr-0">
                            Donors:{" "}
                            <p className="text-theme-colored font-weight-700">
                              {campaign?.DonorsCount}
                            </p>
                          </li>
                          <li className="font-weight-400 text-black-333 pr-0">
                            Raised:{" "}
                            <p className="text-theme-colored font-weight-700">
                              Rs. {campaign?.RaisedAmount || 0}
                            </p>
                          </li>
                          {campaign?.GoalAmount !== 0 && (
                            <li className="font-weight-400 text-black-333 pr-0">
                              Goal:{" "}
                              <p className="text-theme-colored font-weight-700">
                                Rs. {campaign?.GoalAmount}
                              </p>
                            </li>
                          )}
                          <li>
                        <div className="progress-item mt-15">
                          <div className="progress mb-0">
                            <div
                              data-percent={`${Math.round(
                                (Number(campaign?.RaisedAmount || 0) /
                                  Number(campaign?.GoalAmount)) *
                                  100
                              )}`}
                              className="progress-bar"
                            >
                              <span className="percent">
                                {Math.round(
                                  (campaign?.RaisedAmount /
                                    campaign?.GoalAmount) *
                                    100
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                        </div>
                      </InfoSection>
                      <MakeDonationWrapper
                        className="widget"
                        isFullWidth={true}
                      >
                        <div className="pl-0">
                          <div className="col-12">
                            <h3 className="mt-0 line-bottom pt-5">
                              Make a Donation
                              <span className="font-weight-300"> Now!</span>
                            </h3>

                            <div>
                              <p>
                                A small amount can change the life of a child;
                                through education, nutrition, and basic
                                protection, children can lead normal lives.
                              </p>
                            </div>

                            <form id="popup_paypal_donate_form_onetime_recurring">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group mt-20 mb-20">
                                    <p>
                                      <label>
                                        <strong>Payment Type</strong>
                                      </label>
                                    </p>
                                    <label className="radio-inline">
                                      <input
                                        type="radio"
                                        value="one_time"
                                        name="payment_type"
                                        checked
                                      />
                                      One Time
                                    </label>
                                  </div>
                                </div>

                                <div className="col-sm-12">
                                  <div className="form-group mb-20">
                                    <label>
                                      <strong>
                                        How much do you want to donate?
                                      </strong>
                                    </label>
                                    <div className="radio mt-5">
                                      <Row className="mb-15">
                                        <Col md="4">
                                          <label className="radio-inline">
                                            <input
                                              type="radio"
                                              name="amount"
                                              value="20"
                                              onClick={() =>
                                                onAmountChange("200")
                                              }
                                            />
                                            Rs.200/-
                                          </label>
                                        </Col>
                                        <Col md="4">
                                          <label className="radio-inline">
                                            <input
                                              type="radio"
                                              name="amount"
                                              value="50"
                                              onClick={() =>
                                                onAmountChange("500")
                                              }
                                            />
                                            Rs.500/-
                                          </label>
                                        </Col>
                                        <Col md="4">
                                          <label className="radio-inline">
                                            <input
                                              type="radio"
                                              name="amount"
                                              value="100"
                                              onClick={() =>
                                                onAmountChange("1000")
                                              }
                                            />
                                            Rs.1000/-
                                          </label>
                                        </Col>
                                      </Row>
                                      <Row className="mb-15">
                                        <Col md="4">
                                          <label className="radio-inline">
                                            <input
                                              type="radio"
                                              name="amount"
                                              value="200"
                                              onClick={() =>
                                                onAmountChange("2000")
                                              }
                                            />
                                            Rs.2000/-
                                          </label>
                                        </Col>
                                        <Col md="6">
                                          <label className="radio-inline">
                                            <input
                                              type="radio"
                                              name="amount"
                                              value="500"
                                              onClick={() =>
                                                onAmountChange("5000")
                                              }
                                            />
                                            Rs.5000/-
                                          </label>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col md="12">
                                          <label className="radio-inline">
                                            <input
                                              type="radio"
                                              name="amount"
                                              value="other"
                                            />
                                            Other Amount
                                          </label>
                                        </Col>
                                        <Col md="12 mt-15">
                                          <div id="custom_other_amount">
                                            <input
                                              type="number"
                                              name="amount"
                                              value={amount}
                                              onChange={(e) =>
                                                onAmountChange(e.target.value)
                                              }
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-sm-12">
                                  <div className="form-group">
                                    {disableDonate ? (
                                      <Link
                                        to="/"
                                        onClick={(event) =>
                                          event.preventDefault()
                                        }
                                      >
                                        <button
                                          type="submit"
                                          className="btn btn-flat btn-success btn-theme-colored mt-10 pl-30 pr-30 w-100"
                                          data-loading-text="Please wait..."
                                        >
                                          Donate Now
                                        </button>
                                      </Link>
                                    ) : (
                                      <Link to="/donation-form">
                                        <button
                                          type="submit"
                                          className="btn btn-flat btn-success btn-theme-colored mt-10 pl-30 pr-30 w-100"
                                          data-loading-text="Please wait..."
                                        >
                                          Donate Now
                                        </button>
                                      </Link>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <h5 className="total-amount">
                                Total Amount <img src={rightArrowImg} alt="" />{" "}
                                <b>Rs. {amount}</b>
                              </h5>
                            </form>
                          </div>
                        </div>
                      </MakeDonationWrapper>
                    </>
                  )}
                </div>
              </div> */}
            </div>
          </div>
        </section>

        {/* <AddToCart className="hidden-lg hidden-md hidden-sm">
          <section>
            <div className="mr-20">Total Amount: Rs. {amount}/-</div>
            <div>
              {disableDonate ? (
                <Link
                  to="/"
                  className="btn btn-danger btn-theme-colored btn-xs font-16"
                  onClick={(event) => event.preventDefault()}
                >
                  Donate{" "}
                  <i className="flaticon-charity-make-a-donation font-16 ml-5"></i>
                </Link>
              ) : (
                <Link
                  to="/donation-form"
                  className="btn btn-danger btn-theme-colored btn-xs font-16"
                >
                  Donate{" "}
                  <i className="flaticon-charity-make-a-donation font-16 ml-5"></i>
                </Link>
              )}
            </div>
          </section>
        </AddToCart> */}
      </div>
    </CampaignDetailsWrapper>
  );
}
