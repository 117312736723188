import { Route, Switch } from "react-router-dom";
import React from "react";
import Home from "../Home";
import OurCampaigns from "../OurCampaigns";
import CampaignDetails from "../OurCampaigns/CampaignDetails";
import DonateNow from "../Pages/DonateNow";
import BecomeVolunteer from "../Pages/BecomeVolunteer";
import CampaignWithus from "../Pages/CampaignWithus";
import AboutUs from "../Pages/AboutUs";
import Impact from "../Pages/Impact";
import Story from "../Pages/Story";
import Mission from "../Pages/Mission";
import DonationForm from "../Pages/DonationForm";
import Volunteers from "../Pages/Volunteers";
import FAQ from "../Pages/FAQ";
import ScrollIntoView from "../../components/ScrollIntoView";
import CampaignGallery from "../Pages/CampaignGallery";
import Contact from "../Pages/Contact";
import MemberRegistration from "../Pages/MemberRegistration";
import StaticPageDonateNow from "../Pages/StaticPageDonateNow";

export function Router() {
  return (
    <ScrollIntoView>
      <Switch>
        <Route exact path={"/"} component={Home} />
        <Route path="/home" component={Home} />
        <Route path="/our-campaigns" component={OurCampaigns} />
        <Route path="/campaign-details/:id" component={CampaignDetails} />
        <Route path="/donate-now" component={DonateNow} />
        <Route path="/become-volunteer" component={BecomeVolunteer} />
        <Route path="/campaign-withus" component={CampaignWithus} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/impact" component={Impact} />
        <Route path="/story" component={Story} />
        <Route path="/mission" component={Mission} />
        <Route path="/donation-form" component={DonationForm} />
        <Route path="/volunteers" component={Volunteers} />
        <Route path="/faq" component={FAQ} />
        <Route path="/campaigngallery" component={CampaignGallery} />
        <Route path="/Contact" component={Contact} />
        <Route path="/MemberRegistration" component={MemberRegistration} />
        <Route path="/StaticPageDonateNow" component={StaticPageDonateNow} />
      </Switch>
    </ScrollIntoView>
  );
}

export default Router;
