import styled from "styled-components";

export const SectionFooter = styled.section`
  background-color: #515151;

  h5,
  p,
  i {
    color: white !important;
  }

  .logo-footer {
    width: 100px;
  }

  a {
    color: #fff;
  }

  .fa-facebook,
  .fa-instagram {
    color: #000 !important;
  }

  .widget {
    .latest-posts {
      a {
        &:hover {
          text-decoration: none;
          font-weight: 600;
        }
      }
    }
  }
`;
