import React from "react";
import { Link } from "react-router-dom";
import saveChildren from "../../assets/images/saveChildren.jpg";
// import saveChildren1 from '../../assets/images/saveChildren1.jpg';

export default function Story() {
  return (
    <div className="main-content">
      <section>
        <div className="container pt-70 pb-40">
          <div className="section-content">
            <div className="row">
              <div className="col-md-8">
                <h3 className="text-uppercase font-28 letter-space-3 mt-0">
                  We are <span className="text-theme-colored">Ready</span> We
                  make excellent world and save the children.
                </h3>
                <h5 className="text-uppercase font-weight-400">
                  Cum sociis natoque penatibus et ultrices volutpat. Nullam wisi
                  ultricies a, gravida vitae, dapibus risus ante sodales lectus.
                </h5>

                <div className="row">
                  <div className="col-md-6">
                    <p>
                      Etiam ullamcorper. Suspendisse a pellentesque dui, non
                      felis. Maecenas malesuada elit lectus felis, malesuada
                      ultricies. Curabitur et ligula. Ut mole stie a, ultricies
                      porta urna. Vestibulum commodo volutpat a, convallis ac,
                      laoreet enim. Phasellus fermen.{" "}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p>
                      Etiam ullamcorper. Suspendisse a pellentesque dui, non
                      felis. Maecenas malesuada elit lectus felis, malesuada
                      ultricies. Curabitur et ligula. Ut mole stie a, ultricies
                      porta urna. Vestibulum commodo volutpat a, convallis ac,
                      laoreet enim. Phasellus fermen.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="thumb">
                  <img alt="" src={saveChildren} className="img-fullwidth" />
                </div>
              </div>
            </div>
            <div className="row mt-40">
              <div className="col-md-4">
                <div className="icon-box p-0">
                  <Link
                    className="icon-border-effect effect-circled  icon icon-circled mb-0 mr-0 pull-left icon-lg"
                    to="/"
                  >
                    <i className="flaticon-charity-food-donation text-theme-colored font-54"></i>
                  </Link>
                  <div className="ml-120">
                    <h5 className="icon-box-title mt-15 mb-10 text-uppercase">
                      Food
                    </h5>
                    <p className="text-gray">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="icon-box p-0">
                  <Link
                    className="icon-border-effect effect-circled  icon icon-circled mb-0 mr-0 pull-left icon-lg"
                    to="/"
                  >
                    <i className="flaticon-charity-person-inside-a-heart text-theme-colored font-54"></i>
                  </Link>
                  <div className="ml-120">
                    <h5 className="icon-box-title mt-15 mb-10 text-uppercase">
                      Health
                    </h5>
                    <p className="text-gray">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="icon-box p-0">
                  <Link
                    className="icon-border-effect effect-circled  icon icon-circled mb-0 mr-0 pull-left icon-lg"
                    to="/"
                  >
                    <i className="flaticon-charity-shelter text-theme-colored font-54"></i>
                  </Link>
                  <div className="ml-120">
                    <h5 className="icon-box-title mt-15 mb-10 text-uppercase">
                      Accomodation
                    </h5>
                    <p className="text-gray">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="divider parallax layer-overlay overlay-deep">
        <div className="container pt-40 pb-60">
          <div className="row text-justify">
            <div className="col-md-6">
              <h3 className="line-bottom">Who we are</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas
                doloribus facere perferendis eveniet ipsam reiciendis cumque
                aspernatur natus! Voluptatem laudantium totam, quia reiciendis
                quibusdam voluptate architecto impedit id iste rem mollitia enim
                reprehenderit fugit exercitationem ab placeat debitis vel
                excepturi molestiae laboriosam aut. Possimus expedita sint neque
                voluptatibus, odio, architecto, excepturi corrupti magnam sunt
                ipsa voluptatem consequuntur iusto quo, molestiae dolorem
                repudiandae. Consectetur dolorem placeat ratione eum quasi
                delectus, corrupti.
              </p>
            </div>
            <div className="col-md-6">
              <h3 className="line-bottom">Mission</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas
                doloribus facere perferendis eveniet ipsam reiciendis cumque
                aspernatur natus! Voluptatem laudantium totam, quia reiciendis
                quibusdam voluptate architecto impedit id iste rem mollitia enim
                reprehenderit fugit exercitationem ab placeat debitis vel
                excepturi molestiae laboriosam aut. Possimus expedita sint neque
                voluptatibus, odio, architecto, excepturi corrupti magnam sunt
                ipsa voluptatem consequuntur iusto quo, molestiae dolorem
                repudiandae. Consectetur dolorem placeat ratione eum quasi
                delectus, corrupti.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
