import styled from "styled-components";
import { cardRadius } from "../../variables";

interface Selected {
  isOpen?: boolean;
  isFullWidth?: boolean;
}

export const CampaignDetailsWrapper = styled.div`
  .title {
    font-weight: bold;
    letter-spacing: 0.6px;
  }

  .youtube-video {
    iframe {
      display: block !important;
      height: 500px;

      @media (max-width: 480px) {
        height: auto;
      }
    }
  }

  .left-panel {
    flex: 1;
    padding-right: 30px;
  }

  .right-panel {
    position: sticky;
    top: 10vh;
    width: 280px;
    max-width: max-content;
  }

  .event-details p {
    text-align: justify;
  }

  .banner-content-section {
    background-color: #ffffff;
    padding: 10px;

    .banner-wrap {
      padding: 0;

      img {
        min-height: max-content;
        max-height: 600px;
        width: 400px;

        @media (max-width: 480px) {
          object-fit: contain;
          min-height: auto;
          max-height: initial;
          width: 100%;
        }
      }
    }
  }

  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: auto;
    text-align: center;
    font-family: arial;
  }

  .price {
    color: grey;
    font-size: 22px;
  }

  .card button {
    border: none;
    outline: 0;
    padding: 12px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
  }

  .card button:hover {
    opacity: 0.7;
  }

  .list-inline li {
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px !important;

    @media (max-width: 480px) {
      margin-bottom: 10px;
    }
  }

  .content-wrap {
    /* border-bottom: solid 1px #ccc;
        padding-bottom: 20px; */
  }
`;

export const InfoSection = styled.ul<Selected>`
  padding: 2rem;
  border: solid 1px #eee;
  box-shadow: 0 0 10px #eee;
  background-color: white;
  border-radius: 15px;
  max-width: ${(props: any) => (props.isFullWidth ? "100%" : "30%")};
  display: flex;
  margin-bottom: 30px;
`;

export const MakeDonationWrapper = styled.div<Selected>`
  padding: 2rem;
  border: solid 1px #eee;
  background-color: #eee;
  border-radius: 15px;
  max-width: ${(props: any) => (props.isFullWidth ? "100%" : "30%")};
  display: flex;

  @media (max-width: 767px) {
    max-width: 100%;
  }

  .terms-condtions-row {
    label {
      display: flex;
    }
  }

  h3 {
    font-size: 16px;
  }

  .tabs {
    flex: 1;
    display: flex;
    padding: 15px 0;

    .tab-item {
      flex: 1;
      text-align: center;
    }

    .active {
      border-bottom: solid 2px ${(props: any) => props.theme.primary.THEME};
      padding-bottom: 7px;
      color: ${(props: any) => props.theme.primary.THEME};
      font-weight: 600;
    }
  }
`;

export const AddToCart = styled.div`
  width: 100%;
  bottom: 0;
  padding: 0.5rem 2rem;
  position: fixed;
  z-index: 1;
  background-color: green;
  right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-size: 1.5rem;
    padding: 1rem 2rem;
  }
`;

export const CustomTab = styled.div`
  padding: 2em;
  background-image: url("images/bg/bg3.jpg");

  .grids-row {
    padding-bottom: 10px;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      overflow-x: auto;
    }
  }

  h4 {
    color: #be1616;
    text-decoration: underline;
  }

  .nav-tabs li a {
    font-size: 1.5rem;
  }

  .card {
    min-height: auto;
    padding: 0;
    margin-bottom: 10px;
    border-radius: ${cardRadius};
    background-color: white;
    width: 150px;

    @media (max-width: 768px) {
      width: 100%;
    }

    img {
      height: 150px;
      object-fit: contain;
      border-top-left-radius: ${cardRadius};
      border-top-right-radius: ${cardRadius};
      padding: 0.5rem;
      background: #fff;
    }

    .btn-add {
      background-color: #24ae5c;
    }

    .card-body {
      padding: 0 1em 0.5em 1em;

      h6 {
        /* height: 3rem; */
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .card-title {
      font-size: 1rem;
      margin: 1rem;
    }

    .card-text {
      font-size: 1rem;
      margin: 0 0 5px;
    }

    button {
      border: none;
      outline: 0;
      padding: 4px;
      color: white;
      background-color: #000;
      text-align: center;
      cursor: pointer;
      width: 100%;
      font-size: 1.2rem;
    }
  }

  .plus-minus-wrapper {
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;

    img {
      height: auto;
      width: 2.2rem;
      cursor: pointer;
      padding: initial;
      background: none;

      &:hover {
        transform: scale(1.1);
      }
    }

    .plus-minus-custom-input {
      width: auto;
      max-width: 3rem;
      border: solid 1px #eee;
      text-align: center;
      color: green;
      font-weight: bold;
      font-size: 2rem;
    }
  }
`;

export const IndividualCampaign = styled.div`
  padding: 0 20px;
`;
