import React from "react";
import { AppContainer } from "./styles";
import Header from "./GenericComponents/Header";
import Footer from "./GenericComponents/Footer";

function ApplicationEntry(Props: any) {
  return (
    <>
      <div className={"d-flex vh-100"}>
        <AppContainer>
          <Header />
          <Footer />
          {/* <div
            className="d-flex align-items-center justify-content-center vh-100 w-100 p-5"
            style={{
              flexDirection: "column",
              top: "280px",
              position: "relative",
              height: "100%",
            }}
          >
            <h1 className="text-center">
              Welcome to Bharatiya Telugu Samithi. <br />
              The website is under construction..
            </h1>
            <h2>We're coming soon</h2>
          </div> */}
        </AppContainer>
      </div>
    </>
  );
}

export default ApplicationEntry;
