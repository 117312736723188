export const theme = {
  primary: {
    THEME: "#ecbf18",
    SECONDARY_THEME: "#ecbf18",
  },
  standard: {
    WHITE: "#ffffff",
    DARK_BLACK: "#000000",
    LIGHT_BLACK: "#2C282814",
    BRIGHT_BLACK: "#3F404F",
  },
  info: {
    DARK: "#4C86ED",
    DARK_INFO: "#5583D9",
    LIGHT_INFO: "#4164A6",
  },
  danger: {
    DARK_DANGER: "#dc3545",
  },
  warning: {
    DARK_WARNING: "#F7B64A",
    LIGHT_WARNING: "#F0F0F0",
  },
  success: {
    DARK_SUCCESS: "#43B584",
    LIGHT_SUCCESS: "#71a6a0",
  },
  light_primary: {
    LIGHT_PRIMARY: "#AEB9C7",
    LIGHT_SECONDARY: "#757788",
    LIGHT_TERTIARY: "#57647E",
    LIGHT_QUATERNARY: "#B2C3D9",
    LIGHT_QUINARY: "#424D69",
    LIGHT_SENARY: "#868686",
    LIGHT_OCTONARY: "#90A0B7",
    LIGHT_NONARY: "#65738E",
    LIGHT_DENARY: "#7C7C7C",
  },
  light_secondary: {
    LIGHT_PRIMARY: "#F7F7F7",
  },
  tooltip: {
    LIGHT_BORDER: "rgb(178 195 217 / 34%)",
  },
};
