import React, { useState, useEffect } from "react";
import axios from "axios";
import GenericCauses from "../GenericComponents/GenericCause";
import { API_URL } from "../../constants";
import Swal from "sweetalert2";
import { IndividualCampaign } from "./styles";
import Loading from "../../assets/images/loading-new.gif";

interface Campaign {
  id: string;
  CategoryName: string;
  BannerImage: string;
  Title: string;
  TagLine: string;
  Description: string;
  RaisedAmount: number;
  GoalAmount: number;
  DonorsCount: number;
  StartDate: string;
}
export default function OurCampaigns(CustomProps: any) {
  const [campaigns, setCampaigns] = useState<{ [key: string]: Campaign[] }>({});
  const [isLoading, setIsLoading] = useState(false);
  // const [daysLeft, setDaysLeft] = useState(0);

  const getCampaignsData = () => {
    setIsLoading(true);
    axios
      .get(`${API_URL}/campaigns/`)
      .then((response) => {
        const currentYear = new Date().getFullYear();
        const upcomingCampaigns2023: Campaign[] = [];
        const completedCampaigns2023: Campaign[] = [];
        const upcomingCampaigns2024: Campaign[] = [];
        const completedCampaigns2024: Campaign[] = [];

        response.data.data.forEach((campaign: Campaign) => {
          const campaignYear = new Date(campaign.StartDate).getFullYear();
          const campaignEndDate = new Date(campaign.StartDate);
          campaignEndDate.setDate(campaignEndDate.getDate() + 1); // Considering a campaign lasts for one day

          if (campaignYear === 2023) {
            if (campaignEndDate > new Date()) {
              upcomingCampaigns2023.push(campaign);
            } else {
              completedCampaigns2023.push(campaign);
            }
          } else if (campaignYear === 2024) {
            if (campaignEndDate > new Date()) {
              upcomingCampaigns2024.push(campaign);
            } else {
              completedCampaigns2024.push(campaign);
            }
          }
        });

        setCampaigns({
          "2024": [...upcomingCampaigns2024, ...completedCampaigns2024],
          "2023": [...upcomingCampaigns2023, ...completedCampaigns2023],
        });
        setIsLoading(false);

        // const oneDay = 24 * 60 * 60 * 1000;
        // let startDate: any = new Date();
        // let endDate: any = new Date(response.data.data.EndDate);

        // const diffDays = Math.round(Math.abs((startDate - endDate) / oneDay));
        // console.log("----------------->", diffDays);
        // console.log("----------------->", endDate);
        // setDaysLeft(diffDays);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        Swal.fire({
          title: "Error!",
          text: error?.response?.data?.error?.message
            ? error?.response?.data?.error?.message
            : "Something went wrong! Please reload the web page.",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  useEffect(() => {
    getCampaignsData();
  }, []);

  return (
    <div className="main-content">
      <div className="container">
        {Object.entries(campaigns)
          .reverse() // Reverse the order of entries
          .map(([year, campaignList]: any) => (
            <section key={year}>
              <h3 className="line-bottom border-bottom mt-50">{year}</h3>
              {isLoading ? (
                <div className="custom-loading mt-20 mb-20">
                  {[...Array(4)].map((_, index) => (
                    <img
                      key={index}
                      className="floating col-sm-3"
                      src={Loading}
                      alt=""
                    />
                  ))}
                </div>
              ) : (
                <div className="mt-20 p-0 d-flex flex-wrap">
                  {campaignList.map((item: Campaign, index: number) => (
                    <IndividualCampaign
                      key={index.toString()}
                      className="col-sm-12"
                    >
                      <GenericCauses
                        categoryName={item?.CategoryName}
                        banner={item.BannerImage}
                        title={item.Title}
                        TagLine={item.TagLine}
                        description={item.Description}
                        raisedAmount={item.RaisedAmount || 0}
                        percent={Math.round(
                          (Number(item.RaisedAmount || 0) /
                            Number(item.GoalAmount)) *
                            100
                        )}
                        id={item.id}
                        isDonorsCount={item.DonorsCount}
                        isStartDate={item.StartDate}
                        // goalAmount={item.GoalAmount}
                        // isDaysLeft={daysLeft}
                      />
                    </IndividualCampaign>
                  ))}
                </div>
              )}
            </section>
          ))}
      </div>
    </div>
  );
}
