import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { GalleryWrapper } from "./styles";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

// Dynamically import all images from the specified folder
const diwaliImageContext = require.context(
  "../../assets/images/gallery/2023/diwali/",
  false,
  /\.(jpg|JPG|jpeg|png)$/
);
const diwaliImageList = diwaliImageContext
  .keys()
  .map((key) => diwaliImageContext(key).default);

const sriramanavamiImageContext = require.context(
  "../../assets/images/gallery/2023/sriramanavami/",
  false,
  /\.(jpg|JPG|jpeg|png)$/
);
const sriramanavamiImageList = sriramanavamiImageContext
  .keys()
  .map((key) => sriramanavamiImageContext(key).default);

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <button className="slick-arrow slick-next" onClick={onClick}>
      <i className="fa fa-chevron-circle-right" />
    </button>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button className="slick-arrow slick-prev" onClick={onClick}>
      <i className="fa fa-chevron-circle-left" />
    </button>
  );
};

const CampaignGallery = () => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [diwaliLightboxOpen, setDiwaliLightboxOpen] = useState(false);
  const [sriramanavamiLightboxOpen, setSriramanavamiLightboxOpen] =
    useState(false);

  const [loading, setLoading] = useState(true);

  // Handle image load completion
  useEffect(() => {
    const handleImageLoad = () => setLoading(false);

    const diwaliImageElements = diwaliImageList.map((src) => {
      const img = new Image();
      img.src = src;
      img.onload = handleImageLoad;
      return img;
    });

    const sriramanavamiImageElements = sriramanavamiImageList.map((src) => {
      const img = new Image();
      img.src = src;
      img.onload = handleImageLoad;
      return img;
    });

    return () => {
      diwaliImageElements.forEach((img) => (img.onload = null));
      sriramanavamiImageElements.forEach((img) => (img.onload = null));
    };
  }, []);

  const openDiwaliLightbox = (index) => {
    setSelectedImageIndex(index);
    setDiwaliLightboxOpen(true);
  };

  const openSriramanavamiLightbox = (index) => {
    setSelectedImageIndex(index);
    setSriramanavamiLightboxOpen(true);
  };

  const closeLightbox = () => {
    setDiwaliLightboxOpen(false);
    setSriramanavamiLightboxOpen(false);
  };

  const sliderSettings = {
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };

  return (
    <div className="mt-30 mb-40">
      <div className="container">
        <GalleryWrapper>
          {loading ? (
            <div className="loader-container">
              <div className="loader"></div>
              <div className="loader-text">Please wait... It's loading</div>
            </div>
          ) : (
            <>
              <h3 className="line-bottom border-bottom mt-0 mt-sm-20">2023</h3>

              <section>
                <h3 className="mt-0">Diwali Lakshmi Pooja Celebrations</h3>
                <Slider {...sliderSettings}>
                  {diwaliImageList.map((image, index) => (
                    <div
                      key={index}
                      className="gallery-item"
                      onClick={() => openDiwaliLightbox(index)}
                    >
                      <img
                        className="img-responsive"
                        src={image}
                        alt={`Gallery ${index + 1}`}
                      />
                    </div>
                  ))}
                </Slider>
              </section>

              <section>
                <h3 className="mt-0">Sri Rama Navami Celebrations</h3>

                <Slider {...sliderSettings}>
                  {sriramanavamiImageList.map((image, index) => (
                    <div
                      key={index}
                      className="gallery-item"
                      onClick={() => openSriramanavamiLightbox(index)}
                    >
                      <img
                        className="img-responsive"
                        src={image}
                        alt={`Gallery ${index + 1}`}
                      />
                    </div>
                  ))}
                </Slider>
              </section>
            </>
          )}
        </GalleryWrapper>

        {(diwaliLightboxOpen || sriramanavamiLightboxOpen) && (
          <Lightbox
            mainSrc={
              diwaliLightboxOpen
                ? diwaliImageList[selectedImageIndex]
                : sriramanavamiImageList[selectedImageIndex]
            }
            nextSrc={
              diwaliLightboxOpen
                ? diwaliImageList[
                    (selectedImageIndex + 1) % diwaliImageList.length
                  ]
                : sriramanavamiImageList[
                    (selectedImageIndex + 1) % sriramanavamiImageList.length
                  ]
            }
            prevSrc={
              diwaliLightboxOpen
                ? diwaliImageList[
                    (selectedImageIndex + diwaliImageList.length - 1) %
                      diwaliImageList.length
                  ]
                : sriramanavamiImageList[
                    (selectedImageIndex + sriramanavamiImageList.length - 1) %
                      sriramanavamiImageList.length
                  ]
            }
            onCloseRequest={closeLightbox}
            onMovePrevRequest={() =>
              setSelectedImageIndex(
                (selectedImageIndex +
                  (diwaliLightboxOpen
                    ? diwaliImageList.length
                    : sriramanavamiImageList.length) -
                  1) %
                  (diwaliLightboxOpen
                    ? diwaliImageList.length
                    : sriramanavamiImageList.length)
              )
            }
            onMoveNextRequest={() =>
              setSelectedImageIndex(
                (selectedImageIndex + 1) %
                  (diwaliLightboxOpen
                    ? diwaliImageList.length
                    : sriramanavamiImageList.length)
              )
            }
          />
        )}
      </div>
    </div>
  );
};

export default CampaignGallery;
