import React, { useState, useEffect } from "react";
import { MakeDonationWrapper } from "../OurCampaigns/styles";
import { Row, Col } from "reactstrap";
import axios from "axios";
import { API_URL } from "../../constants";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const Razorpay = require("razorpay");

export default function DonationForm() {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [acceptPP, setAcceptPP] = useState(false);
  let history = useHistory();

  const generateOrder = () => {
    let amount = Number(sessionStorage.getItem("amount")) * 100;
    axios
      .get(`${API_URL}/payment/${amount}`)
      .then((response) => {
        sessionStorage.setItem("order_id", response.data.order.id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onNameChange = (val: any) => {
    sessionStorage.setItem("Name", val);
    setName(val);
  };

  const onPhoneChange = (val: any) => {
    sessionStorage.setItem("Phone", val);
    setMobile(val);
  };
  const onEmailChange = (val: any) => {
    sessionStorage.setItem("Email", val);
    setEmail(val);
  };
  const onAcceptingPP = (val: any) => {
    sessionStorage.setItem("acceptPP", true.toString());
    setAcceptPP(true);
  };

  console.log(name, mobile, email, acceptPP, Razorpay);

  const updateDataToBackend = (response: any) => {
    let payload = {
      Name: sessionStorage.getItem("Name"),
      Mobile: sessionStorage.getItem("Phone"),
      Email: sessionStorage.getItem("Email"),
      accepted: true,
      amount: sessionStorage.getItem("amount"),
      DonationFor: sessionStorage.getItem("DonationFor"),
      Category: sessionStorage.getItem("Category"),
      CategoryId: sessionStorage.getItem("CategoryId"),
      CampaignFor: sessionStorage.getItem("CampaignFor"),
      CampaignId: sessionStorage.getItem("CampaignId"),
      Date: new Date(),
      ItemName: sessionStorage.getItem("ItemName"),
      ItemImage: sessionStorage.getItem("ItemImage"),
      ItemPrice: sessionStorage.getItem("ItemPrice"),
      ItemQuantity: sessionStorage.getItem("ItemQuantity"),
      TotalDonation: sessionStorage.getItem("amount"),
      RPOrderID: response.razorpay_order_id,
      RPPaymentId: response.razorpay_payment_id,
      RPSignature: response.razorpay_signature,
    };

    axios
      .post(`${API_URL}/user-orders/`, payload)
      .then((response) => {
        Swal.fire({
          title: "Payment has been successfully done.",
          text: `Thank you for donating!`,
          icon: "success",
          confirmButtonText: "OK",
          backdrop: true,
        });

        history.push("/home");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const guest = () => {
    sessionStorage.setItem("Name", "Anonymous");
    setName("Anonymous");
    sessionStorage.setItem("Phone", "Unknown");
    setMobile("Unknown");
    sessionStorage.setItem("Email", "NA");
    setEmail("NA");
    sessionStorage.setItem("acceptPP", true.toString());
    setAcceptPP(true);

    displayRazorpay();
  };

  async function displayRazorpay() {
    const options = {
      //live mode key
      // key: "",
      //Test mode key
      key: "rzp_test_Eggka2LFotQQQ0",
      amount: Number(sessionStorage.getItem("amount")) * 100,
      currency: "INR",
      name: "Help a needy.",
      description: "",
      order_id: sessionStorage.getItem("order_id"),
      handler: async function (response: any) {
        // const data = {
        //     orderCreationId: order_id,
        //     razorpayPaymentId: response.razorpay_payment_id,
        //     razorpayOrderId: response.razorpay_order_id,
        //     razorpaySignature: response.razorpay_signature,
        // };

        // const result = await axios.post("http://localhost:5000/payment/success", data);

        updateDataToBackend(response);
      },
      prefill: {
        name: sessionStorage.getItem("Name"),
        email: sessionStorage.getItem("Email"),
        contact: sessionStorage.getItem("Phone"),
      },
      notes: {
        // address: "",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new (window as any).Razorpay(options);
    paymentObject.open();

    const rzp = new (window as any).Razorpay(options);
    rzp.open();
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    generateOrder();
  }, []);

  return (
    <div className="container mt-30">
      <Row>
        <Col md="8">
          <MakeDonationWrapper className="widget" isFullWidth={true}>
            <div className="pl-0">
              <div className="col-12">
                <h3 className="mt-0 line-bottom">
                  Please fill the details
                  <span className="font-weight-300"> </span>
                </h3>

                <div className="row">
                  <div className="col-sm-12">
                    <p>
                      By supporting help a needy, you are helping us reach out
                      to more campaigns like this and scale our impact. We'll
                      use a portion of your tip to promote campaigns, manage
                      logistics and also donate to various causes.
                    </p>
                  </div>

                  <div className="col-sm-12 mt-15">
                    <div className="form-group mb-20">
                      <label>
                        <>Name:</>
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        onChange={(e) => onNameChange(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="form-group mb-20">
                      <label>
                        <>Mobile Number:</>
                      </label>
                      <input
                        type="number"
                        name="phone"
                        className="form-control"
                        onChange={(e) => onPhoneChange(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="form-group mb-20">
                      <label>
                        <>Email ID:</>
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        onChange={(e) => onEmailChange(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 terms-condtions-row">
                    <div className="form-group mb-20">
                      <label className="d-flex">
                        <input
                          type="checkbox"
                          name="terms"
                          className="form-control"
                          style={{ width: "auto", height: "auto" }}
                          onChange={(e) => onAcceptingPP(e.target.value)}
                        />
                        <span className="ml-10">
                          By continuing, you are agreeing to Terms of Use and
                          Privacy Policy
                        </span>
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="form-group col-sm-3">
                      <button
                        className="btn btn-flat btn-dark btn-theme-colored mt-10 pl-30 pr-30"
                        onClick={() => {
                          displayRazorpay();
                        }}
                      >
                        Donate Now
                      </button>
                    </div>
                    <div className="form-group col-sm-3">
                      <button
                        className="btn btn-flat btn-dark btn-primary mt-10 pl-30 pr-30"
                        data-loading-text="Please wait..."
                        onClick={() => {
                          guest();
                        }}
                      >
                        Guest
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MakeDonationWrapper>
        </Col>
      </Row>
    </div>
  );
}
