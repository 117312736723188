import React, { useState } from "react";
import { Link } from "react-router-dom";
import Img1 from "../../assets/images/gallery/2.jpg";
import { TeamMemberWrapper } from "./styles";

import President from "../../assets/images/team-members/president.svg";
import VicePresident from "../../assets/images/team-members/vice-president.jpg";
import GeneralSecretary from "../../assets/images/team-members/general-secretary.jpg";
import Executive from "../../assets/images/team-members/executive-member.jpg";
import Treasury from "../../assets/images/team-members/treasury.jpg";
import MediaRelationDirector from "../../assets/images/team-members/media-relation-director.jpg";
import AboutUsImg from "../../assets/images/aboutus.svg";

export default function AboutUs() {
  const [members, setMembers] = useState([
    {
      id: 1,
      src: President,
      name: "Guru Subramanyam Kanakadandi",
      position: "FOUNDER, PRESIDENT",
      description:
        "Guru Subramanyam Kanakadandi, a proud native of Kadapa in Andhra Pradesh. Completed Masters in Automotive Engineering from Politecnico di Torino and currently contributes expertise as an automotive engineer at Joynext. Beyond my professional pursuit, I'm  committed to work for the community’s betterment. ",
      showFullDescription: false,
    },
    {
      id: 2,
      src: VicePresident,
      name: "Nageswara Rao Venthrumilli",
      position: "FOUNDER, VICE PRESIDENT",
      description:
        "I'm a distinguished individual, possessing a Bachelor's degree from Vijayawada. Currently holds the esteemed position of team leader within a renowned multinational corporation Accenture S.p.a. Furthermore, as the Vice President of the Bharatiya Telugu Samiti, focusing on the noble pursuits of culture and community development.",
    },
    {
      id: 3,
      src: GeneralSecretary,
      name: "Mohammed Musthaq",
      position: "FOUNDER, GENERAL SECRETARY",
      description:
        "Mushtaq Mohammed, native of Hyderabad, Telangana and living in Torino, Italy from 2017. Completed MSc in Automotive from POLITO and currently working as an ADAS Test Strategy and Planning engineer with Stellantis Europe. Hailing from Hyderabad I bring in a blend of tradition and modernity to our community. Being a part of this incredible community that celebrates the essence of Telugu culture. As someone deeply passionate about our rich heritage and looking forward to engaging with you all, sharing insights, and contributing to the collective spirit of this association.",
      showFullDescription: false,
    },
    {
      id: 4,
      src: Executive,
      name: "Srinivas Voleti",
      position: "FOUNDER, EXECUTIVE MEMBER",
      description:
        "I hail from Hyderabad, Telangana in India. It was quite a significant transition from Hyderabad to Italy for professional growth and now becoming a Permanent Resident in Italy. I have been working in the Information Technology Industry for over 14 years in designing and implementing effective solutions to meet specific business needs and ensuring that all integrated components work seamlessly together. I believe a synergistic approach is a driving factor for future endeavours.",
      showFullDescription: false,
    },
    {
      id: 5,
      src: Treasury,
      name: "Suresh Metla",
      position: "FOUNDER, TREASURER",
      description:
        "I’m Suresh Metla, originally from Khammam, Telangana, India. I earned my bachelor's degree from MIST, Hyderabad, and I am currently employed as an IT consultant. Additionally, I am actively involved in supporting the Bharatiya Telugu Samithi association in the role of treasury.",
      showFullDescription: false,
    },

    {
      id: 6,
      src: MediaRelationDirector,
      name: "Kranthi Thej Kandula",
      position: "DIRECTOR OF MEDIA RELATIONS & CULTURAL AFFAIRS",
      description:
        "My name is Kranthi Thej Kandula, hailing from Kadapa, Andhra Pradesh.I did my Masters in Computer Science (Sapienza Università di Roma) Currently working as a Researcher at Unito. My passions include photography, video making and event organizi Worked as a dop for 12+ short films back in India.",
      showFullDescription: false,
    },
  ]);

  // Function to toggle between full and truncated descriptions
  const toggleDescription = (index: any) => {
    const updatedVolunteers = [...members];
    updatedVolunteers[index].showFullDescription =
      !updatedVolunteers[index].showFullDescription;
    setMembers(updatedVolunteers);
  };

  return (
    <div className="main-content">
      <section>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <h3 className="line-bottom border-bottom mt-0 mt-sm-20">
                About Us
              </h3>
              <img src={AboutUsImg} className="img-fullwidth" alt="" />
              <h3 className="mt-50 mb-20">
                <b>
                  We Welcome everyone to Bharatiya Telugu Samithi, where we
                  strive to make every celebration unforgettable!
                </b>
              </h3>
              <p className="mb-20">
                We created this platform as a beginner's step for a formal
                portraiture of our Telugu people needs to help them to connect
                and to access the information for our better future here in
                Italy.
              </p>
              <p className="mb-20">
                As an NGO dedicated to fostering cultural heritage and community
                engagement, we specialize in organizing vibrant events  to bring
                people together in the spirit of unity and joy.
              </p>
            </div>
          </div>

          <div className="row mt-50 pt-50">
            <div className="col-xs-12">
              <h3 className="line-bottom border-bottom mt-0 mt-sm-20">
                Meet Our Team
              </h3>
              <div className="row d-flex flex-wrap">
                {members.map((item: any) => {
                  return (
                    <div className="col-xs-12 col-sm-4">
                      <TeamMemberWrapper className="icon-box left media bg-silver-light border-1px p-15 mb-20">
                        <img src={item.src} alt="" />
                        <div className="media-body">
                          <h4 className="mt-30">
                            <b>{item.name}</b>
                          </h4>
                          <p className="position">{item.position}</p>
                          <p>
                            {item.showFullDescription
                              ? item.description
                              : `${item.description.slice(0, 150)}...`}
                            {item.description.length > 150 && (
                              <span
                                className="text-primary cursor-pointer"
                                onClick={() => toggleDescription(item.id - 1)}
                              >
                                {item.showFullDescription
                                  ? " Read Less"
                                  : " Read More"}
                              </span>
                            )}
                          </p>
                        </div>
                      </TeamMemberWrapper>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
