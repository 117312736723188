import * as types from "../../actions/Members/types";

interface FormState {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  indiaphone: string;
  gender: string;
  ssn: string;
}

const initialState: FormState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  indiaphone: "",
  gender: "",
  ssn: ""
};

export const formReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.FIRST_NAME:
      return { ...state, firstName: action.payload };

    case types.LAST_NAME:
      return { ...state, lastName: action.payload };

    case types.SET_EMAIL:
      return { ...state, email: action.payload };

    case types.SET_PHONE:
      return { ...state, phone: action.payload };

    case types.SET_INDIA_PHONE:
      return { ...state, indiaphone: action.payload };

    case types.SET_GENDER:
      return { ...state, gender: action.payload };

    case types.SET_SSN:
      return { ...state, ssn: action.payload };

    default:
      return state;
  }
};

export default formReducer;
