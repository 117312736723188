import styled from "styled-components";

export const CustomCarousel = styled.div`
  .slider-wrapper {
    height: 70vh !important;

    @media (max-width: 480px) {
      height: 300px !important;
    }

    .slider-content {
      background-size: cover !important;
      background-position: center !important;
      background-repeat: no-repeat !important;

      @media (max-width: 480px) {
        background-size: contain !important;
        height: 300px !important;
      }
    }
  }

  .carousel-slider {
    .control-dots {
      display: none;
    }

    .legend {
      background: #ffb100;
      font-size: 1.4rem;
      color: #121010;
      opacity: 1;
      width: 30%;
      font-weight: bold;
      margin-left: 0;
      position: absolute;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);

      @media (max-width: 480px) {
        margin-left: 0;
        position: relative;
        left: auto;
        margin-right: auto;
        -webkit-transform: initial;
        -ms-transform: translate(-50%, -50%);
        transform: initial;
      }
    }
  }

  .slide-background {
    background-color: #ffde59;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 680px;

    @media (max-width: 480px) {
      height: 300px;
      background-size: cover;
      background-color: #fff;

      .description {
        position: relative;
        top: 100px;
        font-size: 26px;
      }
    }
  }

  .carousel .slide img {
    height: 40em;
    object-fit: cover;

    @media (max-width: 480px) {
      height: auto;
      object-fit: contain;
    }
  }

  .thumbs-wrapper {
    display: flex;
    justify-content: center;
    margin: 5px;
  }

  .carousel .thumb img {
    height: 3em;
  }

  .description {
    text-shadow: 0 0 5px #ff0c0c;
    line-height: 36px;
  }
`;

export const FeaturedMainContent = styled.div`
  .description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
`;

export const VolunteerBannerSection = styled.section`
  .right-wrap {
    img {
      width: 100%;
      height: 15em;
      box-shadow: 0 3px 20px #000;
      border-radius: 20px;
    }

    @media (max-width: 480px) {
      margin-top: 40px;
    }
  }

  .left-wrap {
    h2 {
      color: #15cd15;
      font-size: 5rem;
    }
  }
`;

export const FormPostionFixed = styled.div`
  position: absolute;
  top: 10%;
  z-index: 999;
  left: 1%;
  width: 30%;

  @media (max-width: 767px) {
    position: relative;
    width: 100%;
    top: 2%;
    left: 0;
  }
`;

export const ViewMore = styled.div`
  display: flex;
  justify-content: flex-end;
  right: 2%;
  position: relative;

  a {
    font-weight: 600;
    text-decoration: none;
    color: ${(props: any) => props.theme.primary.THEME};
    font-size: 20px;
  }
`;

export const MainContainer = styled.div`
  .become-volunteer-section {
    background-color: #fff;

    h3 {
      font-size: 30px;
    }

    h2 {
      font-size: 38px;
      font-weight: 600;
      margin-top: 0;
    }

    p {
      font-size: 18px;
      margin-top: 20px;
    }

    .volunteer-banner {
      border-radius: 12px;
    }

    ul {
      li {
        font-size: 18px;
        padding: 10px 0;
      }
    }

    .space {
      padding-left: 40px;

      @media (max-width: 480px) {
        padding-left: 10px;
      }
    }
  }

  .information-section {
    background-color: #14130e;
  }

  .no-results {
    font-size: 16px;
    text-align: center;
    width: 100%;
  }
`;
