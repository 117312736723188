import React from "react";
import OurMission from "../../assets/images/OurMission.jpg";

export default function Mission() {
  return (
    <div className="main-content">
      <section>
        <div className="container pt-70 pb-70">
          <div className="section-content">
            <div className="row">
              <div className="col-md-4">
                <img alt="" src={OurMission} className="img-fullwidth" />
              </div>
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-6">
                    <h4>Our Mission</h4>
                    <p>
                      Etiam ullamcorper. Suspendisse a pellentesque dui, non
                      felisaec enas malesuada elit lectus felismales uada
                      ultricies. Curabitur et ligula. Ut mole stieultr cies
                      porta urna. Vestibulum commodo volutpatco vallis ac,
                      laoreet enim. Phasellus fermen. et ligula mole stie.
                    </p>
                  </div>
                  <div className="col-md-6">
                    <ul className="list angle-double-right mt-0">
                      <li>Curabitur et ligula. Ut mole stie.</li>
                      <li>Maecenas malesuada elit lectus felis.</li>
                      <li>Vestibulum commodo volutpat a, convallis.</li>
                      <li>Suspendisse a pellentesque dui, non.</li>
                      <li>Phasellus fermen. et ligula. Ut mole stie.</li>
                      <li>Vestibulum commodo volutpat a, convallis.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-10">
              <div className="col-md-8">
                <h5 className="lead">
                  Cum sociis natoque penatibus et ultrices volutpat. Nullam wisi
                  ultricies a, gravida vitae, dapibus risus ante sodales lectus.
                </h5>
                <div className="row">
                  <div className="col-md-6">
                    <p>
                      Etiam ullamcorper. Suspendisse a pellentesque dui, non
                      felis. Maecenas malesuada elit lectus felis, malesuada
                      ultricies. Curabitur et ligula. Ut mole stie a, ultricies
                      porta urna. Vestibulum commodo volutpat a, convallis ac,
                      laoreet enim. Phasellus fermen.{" "}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p>
                      Etiam ullamcorper. Suspendisse a pellentesque dui, non
                      felis. Maecenas malesuada elit lectus felis, malesuada
                      ultricies. Curabitur et ligula. Ut mole stie a, ultricies
                      porta urna. Vestibulum commodo volutpat a, convallis ac,
                      laoreet enim. Phasellus fermen.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="thumb">
                  <img className="img-fullwidth" src={OurMission} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
