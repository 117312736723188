import React from "react";
import Img1 from "../../assets/1.png";

export default function Impact() {
  return (
    <div className="main-content">
      <section>
        <div className="container pt-70">
          <div className="row">
            <div className="col-md-5">
              <div className="owl-carousel-1col" data-nav="true">
                <div className="item">
                  <img src={Img1} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <h3 className="text-theme-colored mt-0 mb-20">
                Our Impact Report provides information on how we assist and
                quantify the good effects we have on people's lives.
              </h3>
              <p>
                NGOs are there to keep the balance when there is a significant
                imbalance. Organisations such as the Elysium Foundation are
                crucial in ensuring that all children, particularly those from
                low-income backgrounds, have access to education as a means of
                building bright futures.
              </p>

              <p className="mt-10">
                Numerous non-governmental organisations (NGOs) such as the
                Elysium Foundation operate at the local level throughout India
                in an effort to improve the educational system, raise awareness
                of the value of education, and enrol as many kids as possible so
                they can have fulfilling lives.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-lighter">
        <div className="container pt-50 pb-70">
          <div className="row">
            <div className="col-md-7">
              <h3>
                Empowering Rural Communities in Italy: Transforming Lives
                through Development Initiatives
              </h3>
              <p>
                The majority of Italy's population resides in quaint villages,
                yet many face challenges such as limited connectivity,
                inadequate educational resources, and a lack of exposure to
                modern agricultural practices. Life in these rural areas often
                lags behind the conveniences found in urban centers. For the
                holistic progress of the nation, rural development becomes
                paramount, and the impact of NGO programs and projects plays a
                crucial role.
              </p>
              <p>
                In essence, rural development encompasses a range of community
                and economic initiatives aimed at improving the quality of life
                in remote villages and rural areas. Non-Governmental
                Organizations (NGOs) dedicated to rural development in Italy
                strive to enhance the social and economic well-being of
                disadvantaged communities by providing vital financial support.
              </p>
            </div>
            <div className="col-md-5">
              <h3>Our progress</h3>
              <div className="progressbar-container">
                <div className="progress-item">
                  <div className="progress-title">
                    <h6>Community Empowerment</h6>
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar bg-theme-colored"
                      data-percent="85"
                    ></div>
                  </div>
                </div>
                <div className="progress-item">
                  <div className="progress-title">
                    <h6>Education Access</h6>
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar bg-theme-colored"
                      data-percent="73"
                    ></div>
                  </div>
                </div>
                <div className="progress-item">
                  <div className="progress-title">
                    <h6>Agricultural Advancement</h6>
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar bg-theme-colored"
                      data-percent="81"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container pt-40 pb-70">
          <div className="row">
            {/* <div className="col-md-4">
                            <h3 className="">Our previous Impact Reports</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum quia placeat hic blanditiis.</p>
                            <Link className="btn btn-gray mt-10" to="/"><i className="fa fa-file-pdf-o pr-5"></i> Download Annual Impact Report 2015</Link>
                            <Link className="btn btn-gray mt-10" to="/"><i className="fa fa-file-pdf-o pr-5"></i> Download Annual Impact Report 2014</Link>
                            <Link className="btn btn-gray mt-10" to="/"><i className="fa fa-file-pdf-o pr-5"></i> Download Annual Impact Report 2013</Link>
                            <Link className="btn btn-gray mt-10" to="/"><i className="fa fa-file-pdf-o pr-5"></i> Download Annual Impact Report 2012</Link>
                        </div> */}
            <div className="col-md-8">
              <h3 className="text-theme-colored">Our impact this year</h3>
              <p>
                Our Impact Report explains how we help and how we measure our
                positive impact on people’s lives.
              </p>
              <ul className="list theme-colored check ml-30 pt-10">
                <li>
                  Enjoy growing up and are more able to deal with difficult
                  times
                </li>
                <li>Develop positive relationships with friends and family</li>
                <li>Develop their understanding of right and wrong</li>
                <li>Enjoy learning and start doing better at school</li>
                <li>Have a positive identity and self-worth</li>
                <li>Enjoy new experiences</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
