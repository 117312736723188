import React from "react";
import { Link } from "react-router-dom";

export default function BecomeVolunteer() {
  return (
    <div className="main-content">
      <section className="bg-lighter">
        <div className="container pt-70 pb-40">
          <div className="section-content">
            <div className="row mtli-row-clearfix">
              <div className="col-xs-12 col-sm-6 col-md-3 pb-sm-20">
                <div className="icon-box text-center bg-white p-15 pt-30">
                  <Link
                    to="/"
                    className="icon icon-gray icon-bordered icon-rounded icon-border-effect effect-rounded mb-10"
                  >
                    <i className="fa fa-user-plus font-30 text-theme-colored"></i>
                  </Link>
                  <h4 className="icon-box-title">
                    <Link to="/">Create an account</Link>
                  </h4>
                  <p className="text-gray">
                    Create an account on the NGO website
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-3 pb-sm-20">
                <div className="icon-box text-center bg-white p-15 pt-30">
                  <Link
                    to="/"
                    className="icon icon-gray icon-bordered icon-rounded icon-border-effect effect-rounded mb-10"
                  >
                    <i className="fa fa-magic font-30 text-theme-colored"></i>
                  </Link>
                  <h4 className="icon-box-title">
                    <Link to="/">Explore campaigns</Link>
                  </h4>
                  <p className="text-gray">
                    Browse through the different campaigns listed on the website{" "}
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-3 pb-sm-20">
                <div className="icon-box text-center bg-white p-15 pt-30">
                  <Link
                    to="/"
                    className="icon icon-gray icon-bordered icon-rounded icon-border-effect effect-rounded mb-10"
                  >
                    <i className="fa fa-globe font-30 text-theme-colored"></i>
                  </Link>
                  <h4 className="icon-box-title">
                    <Link to="/">Choose a cause</Link>
                  </h4>
                  <p className="text-gray">
                    Choose the cause you would like to be associated with
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-3 pb-sm-20">
                <div className="icon-box text-center bg-white p-15 pt-30">
                  <Link
                    to="/"
                    className="icon icon-gray icon-bordered icon-rounded icon-border-effect effect-rounded mb-10"
                  >
                    <i className="fa fa-life-ring font-30 text-theme-colored"></i>
                  </Link>
                  <h4 className="icon-box-title">
                    <Link to="/">Volunteer</Link>
                  </h4>
                  <p className="text-gray">
                    Volunteer for your chosen cause and get started
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container pt-50 pb-70">
          <div className="row">
            <div className="col-md-12">
              <div className="heading-line-bottom mt-0 mb-30">
                <h3 className="heading-title">Open Positions</h3>
              </div>
              <div className="icon-box mb-0 p-0">
                <Link to="/" className="icon icon-gray pull-left mb-0 mr-10">
                  <i className="pe-7s-users"></i>
                </Link>
                <h3 className="icon-box-title pt-15 mt-0 mb-40">
                  Head coordinator{" "}
                </h3>
                <p className="text-gray">
                  Head coordinator - We are looking for a person with strong
                  management skills to lead the team of volunteers. Experience
                  in a leadership role and a strong passion for social work is
                  preferred.
                </p>
                <Link className="btn btn-dark btn-sm mt-15" to="/">
                  Apply Now
                </Link>
              </div>
              <div className="icon-box mt-80 mb-0 p-0">
                <Link to="/" className="icon icon-gray pull-left mb-0 mr-10">
                  <i className="pe-7s-users"></i>
                </Link>
                <h3 className="icon-box-title pt-15 mt-0 mb-40">Volunteers</h3>
                <p className="text-gray">
                  Volunteers - We are always on the lookout for enthusiastic
                  people with an inherent desire to help those in need. A
                  volunteer will associate themselves with a cause and help in
                  raising funds for particular campaigns.
                </p>
                <Link className="btn btn-dark btn-sm mt-15" to="/">
                  Apply Now
                </Link>
              </div>
            </div>
            {/* <div className="col-md-4">
                            <div className="heading-line-bottom mt-0 mb-30">
                                <h3 className="heading-title">Become a Volunteer</h3>
                            </div>
                            <form id="volunteer_apply_form" name="job_apply_form" action="includes/become-volunteer.php" method="post">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Name <small>*</small></label>
                                            <input id="form_name" name="form_name" type="text" placeholder="Enter Name" className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Email <small>*</small></label>
                                            <input id="form_email" name="form_email" className="form-control required email" type="email" placeholder="Enter Email"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Sex <small>*</small></label>
                                            <select id="form_sex" name="form_sex" className="form-control required">
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Choose Branch <small>*</small></label>
                                            <select id="form_branch" name="form_branch" className="form-control required">
                                                <option value="UK">UK</option>
                                                <option value="USA">USA</option>
                                                <option value="Australia">Australia</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Message <small>*</small></label>
                                    <textarea id="form_message" name="form_message" className="form-control required" placeholder="Your cover letter/message sent to the employer"></textarea>
                                </div>
                                <div className="form-group">
                                    <label>C/V Upload</label>
                                    <input id="form_attachment" name="form_attachment" className="file" type="file" multiple data-show-upload="false" data-show-caption="true"/>
                                    <small>Maximum upload file size: 12 MB</small>
                                </div>
                                <div className="form-group">
                                    <input id="form_botcheck" name="form_botcheck" className="form-control" type="hidden" value="" />
                                    <button type="submit" className="btn btn-block btn-dark btn-theme-colored btn-sm mt-20 pt-10 pb-10" data-loading-text="Please wait...">Apply Now</button>
                                </div>
                            </form>
                        </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}
