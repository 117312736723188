import styled from "styled-components";

export const GalleryWrapper = styled.div`
  section {
    margin-top: 50px;
  }

  h3 {
    padding-bottom: 12px;
    color: black;
    font-weight: 500;
  }

  .slick-slider {
    .slick-slide {
      padding: 10px;

      .gallery-item {
        width: 100%;
        height: 300px;

        img {
          width: 100%;
          height: 260px;
          object-fit: fill;
          border-radius: 6px;
        }
      }
    }

    .slick-prev {
      left: -50px;
    }

    .slick-next {
      right: -50px;
    }

    .slick-prev,
    .slick-next {
      background: black;
      border-radius: 100%;
      color: rgb(170 170 170);
      font-size: 26px;

      &:hover {
        color: black;
      }

      i {
        bottom: 22px;
        position: relative;
        background: white;

        @media (max-width: 480px) {
          border-radius: 100%;
        }
      }
    }

    @media (max-width: 480px) {
      .slick-prev {
        left: 0;
        z-index: 9;
      }

      .slick-next {
        right: 0;
        z-index: 9;
      }
    }
  }
`;

export const MemberRegistrationWrapper = styled.div`
  .banner-img {
    height: 400px;
    object-fit: cover;
    width: 100%;
  }

  .member-registration-form {
    label {
      margin-bottom: 26px;
      width: 100%;

      input,
      select {
        margin-top: 4px;
      }
    }

    input {
      width: 100%;
    }
  }

  form {
    padding: 50px;
    padding-left: 100px;
    padding-right: 100px;
    background-color: #eee;
    border-radius: 8px;
    margin-top: 0;

    @media (max-width: 480px) {
      padding: 10px;
      background-color: transparent;
    }
  }

  .banner {
    background-color: #eee;
  }
`;

export const SectionRegistration = styled.section`
  background-color: #eee;
  box-shadow: 0 0 1px #eee;
  border-radius: 12px;
  padding: 12px;

  .form {
    background: white;
    padding: 30px;
    border-radius: 8px;
  }

  .form-group {
    margin-bottom: 22px;
  }
`;

export const TeamMemberWrapper = styled.section`
  height: auto;

  h4 {
    font-size: 20px;
    color: #b10707;
  }

  .position {
    font-size: 14px;
    color: #975151;
    font-weight: 600;
  }

  strong {
    margin-bottom: 30px;
    font-size: 16px;
    color: #9c7272;
    letter-spacing: 0.6px;
  }

  img {
    width: 100%;
    object-fit: fill;
    height: 300px;
  }
`;
