import React, { FunctionComponent } from "react";
import { CauseBox } from "./styles";
import { useHistory } from "react-router-dom";

interface HeaderObject {
  id?: string;
  title?: string;
  TagLine?: string;
  raisedAmount?: number;
  goalAmount?: number;
  percent?: number;
  description?: any;
  btnTitle?: string;
  banner?: any;
  onClick?: any;
  categoryName?: any;
  isDonorsCount?: any;
  isDaysLeft?: any;
  isStartDate?: any;
}

const GenericCause: FunctionComponent<HeaderObject> = (props: HeaderObject) => {
  const history = useHistory();
  // const [daysLeft, setDaysLeft] = useState(0);

  const navigateTo = (id: any) => {
    history.push(`/campaign-details/${id}`);
  };

  function formatDate(dateString: any) {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  return (
    <CauseBox
      className="mb-30"
      onClick={() => {
        navigateTo(props.id);
      }}
    >
      {/* <div className="category-mark">
        <p>{props.categoryName}</p>
      </div> */}
      <div className="row d-flex flex-wrap">
        <div className="thumb col-sm-4">
          <img src={props.banner} alt={props.banner} />
        </div>
        <div className="causes-details col-sm-8">
          <div className="primary-row">
            <div className="category-mark">
              {new Date(props.isStartDate).getTime() > Date.now() ? (
                <p className="upcoming-label">Upcoming Event</p>
              ) : (
                <p className="completed-label">Completed Event</p>
              )}
            </div>
            <h4 className="" title={props.title}>
              {props.title}
            </h4>
            <p className="" title={props.TagLine}>
              {props.TagLine}
            </p>
            <h5 className="m-0">
              <b>Event Date:</b> {formatDate(props.isStartDate)}
            </h5>

            {/* <p className="m-0">{props.description}</p> */}
            {/* <div className="d-flex mb-5">
              <img src={logoImgWithoutText} alt="" className="logo-img-cause" />
              <span className={"pl-3"}>by Bharatiya Telugu Samithi</span>
              <span className={"pl-3"}>{props.StartDate}</span>
            </div> */}
          </div>

          {/* <div className="secondary-row">
            <div className="d-flex justify-content-between align-items-center amount">
              <div className="d-flex align-items-center left">
                <p>{props.raisedAmount} </p>
                <span>raised</span>
                <b>{props.goalAmount}</b>
              </div>
              <div className="d-flex align-items-center right">
                <p>{props.isDonorsCount}</p>
                <span>Donors</span>
              </div>
            </div>

            <div className="progress-item mt-10">
              <Line
                percent={props.percent}
                strokeWidth={4}
                trailWidth={4}
                strokeColor={theme.primary.THEME}
              />
            </div>

            <div className="social-row">
              <Link to="/" className="btn btn-default btn-md">
                Donate{" "}
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </CauseBox>
  );
};

export default GenericCause;
