import React, { useEffect, useState, FunctionComponent } from "react";
import { HeaderWrapper, MobileMenuWrapper, MenuWrapper } from "./styles";
import Router from "../../routes";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../constants";
import logoImg from "../../../assets/images/logo.png";

const Header: FunctionComponent = () => {
  const location = useLocation();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [favCamp, setFavCamp] = useState({
    id: "",
    BannerImage: "",
    Title: "",
    TagLine: "",
    Description: "",
    DonorsCount: 0,
    GoalAmount: 0,
    RaisedAmount: 0,
    StartDate: "",
    EndDate: "",
    CategoryId: "",
    CategoryName: "",
    CampaignFor: "",
    AddToFavotite: false,
    YoutubeLink: "",
    GalleryImage: "",
  });

  const getFeaturedCampaign = () => {
    axios
      .get(
        `${API_URL}/campaigns/?filter=%7B%0A%20%20%22where%22%3A%20%7B%0A%20%20%20%20%22AddToFavotite%22%3A%20true%0A%20%20%7D%0A%7D`
      )
      .then((response) => {
        setFavCamp(response.data.data[response.data.data.length - 1]);
      })
      .catch((error) => {
        alert(error);
      });
  };

  useEffect(() => {
    getFeaturedCampaign();
  }, []);

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <HeaderWrapper>
        <div className="container">
          <nav>
            <div className="left d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center logo-wrapper">
                <Link
                  to="/home"
                  className="d-flex align-items-center"
                  style={{ textDecoration: "none" }}
                >
                  <img src={logoImg} alt="" className="logo" />
                  <p>
                    Bharatiya Telugu Samithi
                    <br />
                    in Torino
                  </p>
                </Link>{" "}
              </div>
              <i className="fa fa-bars fa-3x" onClick={toggleMobileMenu} />
            </div>

            <MenuWrapper>
              <ul>
                <li className={location.pathname === "/home" ? "active" : ""}>
                  <Link to="/home">Home</Link>
                </li>
                <li
                  className={location.pathname === "/about-us" ? "active" : ""}
                >
                  <Link to="/about-us">About Us</Link>
                </li>
                <li
                  className={
                    location.pathname === "/our-campaigns" ? "active" : ""
                  }
                >
                  <Link to="/our-campaigns">Our Events</Link>
                </li>
                <li
                  className={
                    location.pathname === "/campaigngallery" ? "active" : ""
                  }
                >
                  <Link to="/campaigngallery">Gallery</Link>
                </li>
                <li
                  className={
                    location.pathname === "/MemberRegistration" ? "active" : ""
                  }
                >
                  <Link to="/MemberRegistration">Join Us</Link>
                </li>
                <li
                  className={
                    location.pathname === "/StaticPageDonateNow" ? "active" : ""
                  }
                >
                  <Link to="/StaticPageDonateNow">Donate</Link>
                </li>
                <li
                  className={location.pathname === "/Contact" ? "active" : ""}
                >
                  <Link to="/Contact">Contact</Link>
                </li>
              </ul>
            </MenuWrapper>

            {isMobileMenuOpen && (
              <MobileMenuWrapper className="pt-20">
                {/* <div className="menu-close">
                  <button onClick={closeMobileMenu}>Close</button>
                </div> */}
                <ul>
                  <li className={location.pathname === "/home" ? "active" : ""}>
                    <Link to="/home" onClick={closeMobileMenu}>
                      Home
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/about-us" ? "active" : ""
                    }
                  >
                    <Link to="/about-us" onClick={closeMobileMenu}>
                      About Us
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/our-campaigns" ? "active" : ""
                    }
                  >
                    <Link to="/our-campaigns" onClick={closeMobileMenu}>
                      Our Events
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/campaigngallery" ? "active" : ""
                    }
                  >
                    <Link to="/campaigngallery" onClick={closeMobileMenu}>
                      Gallery
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/MemberRegistration"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/MemberRegistration" onClick={closeMobileMenu}>
                      Join Us
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/StaticPageDonateNow"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/StaticPageDonateNow" onClick={closeMobileMenu}>
                      Donate
                    </Link>
                  </li>
                  <li
                    className={location.pathname === "/Contact" ? "active" : ""}
                  >
                    <Link to="/Contact" onClick={closeMobileMenu}>
                      Contact
                    </Link>
                  </li>
                </ul>
              </MobileMenuWrapper>
            )}
          </nav>
        </div>
      </HeaderWrapper>
      <Router />
    </>
  );
};

export default Header;
