import React from "react";

export default function FAQ() {
  return (
    <div className="main-content">
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <div id="accordion1" className="panel-group accordion">
                <div className="panel">
                  <div className="panel-title">
                    {" "}
                    <a
                      data-parent="#accordion1"
                      data-toggle="collapse"
                      href="#accordion11"
                      className="active"
                      aria-expanded="true"
                    >
                      {" "}
                      <span className="open-sub"></span>{" "}
                      <strong>Q: What is NGO?</strong>
                    </a>{" "}
                  </div>
                  <div
                    id="accordion11"
                    className="panel-collapse collapse in"
                    role="tablist"
                    aria-expanded="true"
                  >
                    <div className="panel-content">
                      <p>
                        NGO is an organisation that raises funds for different
                        charities and organisations. Here, you can find
                        different charities that need your help and you can
                        donate to one or more causes you choose.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="panel">
                  <div className="panel-title">
                    {" "}
                    <a
                      className="collapsed"
                      data-parent="#accordion1"
                      data-toggle="collapse"
                      href="#accordion12"
                      aria-expanded="false"
                    >
                      {" "}
                      <span className="open-sub"></span>{" "}
                      <strong>Q: What is the Mission of NGO?</strong>
                    </a>{" "}
                  </div>
                  <div
                    id="accordion12"
                    className="panel-collapse collapse"
                    role="tablist"
                    aria-expanded="false"
                  >
                    <div className="panel-content">
                      <p>
                        The mission, as the name suggests, is to help the ones
                        in need. There are always people and causes in India
                        that will need financial and moral support. We provide a
                        helping hand to them.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="panel">
                  <div className="panel-title">
                    {" "}
                    <a
                      data-parent="#accordion1"
                      data-toggle="collapse"
                      href="#accordion13"
                      className="collapsed"
                      aria-expanded="false"
                    >
                      {" "}
                      <span className="open-sub"></span>{" "}
                      <strong>Q: How Does NGO Work?</strong>
                    </a>{" "}
                  </div>
                  <div
                    id="accordion13"
                    className="panel-collapse collapse"
                    role="tablist"
                    aria-expanded="false"
                  >
                    <div className="panel-content">
                      <p>
                        We bridge the gap between the ones in need and the ones
                        who can help. There are tons of causes that need your
                        assistance. Donate as much and to as many causes as you
                        wish to.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="panel">
                  <div className="panel-title">
                    {" "}
                    <a
                      data-parent="#accordion1"
                      data-toggle="collapse"
                      href="#accordion14"
                      className="collapsed"
                      aria-expanded="false"
                    >
                      {" "}
                      <span className="open-sub"></span>{" "}
                      <strong>Q: How can I partner with the NGO?</strong>
                    </a>{" "}
                  </div>
                  <div
                    id="accordion14"
                    className="panel-collapse collapse"
                    role="tablist"
                    aria-expanded="false"
                  >
                    <div className="panel-content">
                      <p>
                        You can partner with NGO in two ways. The simplest way
                        is to donate. Another way is to become a volunteer.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="panel">
                  <div className="panel-title">
                    {" "}
                    <a
                      data-parent="#accordion1"
                      data-toggle="collapse"
                      href="#accordion15"
                      className="collapsed"
                      aria-expanded="false"
                    >
                      {" "}
                      <span className="open-sub"></span>{" "}
                      <strong>Q: How can I donate?</strong>
                    </a>{" "}
                  </div>
                  <div
                    id="accordion15"
                    className="panel-collapse collapse"
                    role="tablist"
                    aria-expanded="false"
                  >
                    <div className="panel-content">
                      <p>
                        You can visit the Donate page mentioned at the top of
                        the page. Choose the cause and amount you wish to donate
                        to.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="panel">
                  <div className="panel-title">
                    {" "}
                    <a
                      data-parent="#accordion1"
                      data-toggle="collapse"
                      href="#accordion16"
                      className="collapsed"
                      aria-expanded="false"
                    >
                      {" "}
                      <span className="open-sub"></span>{" "}
                      <strong>Q: How can I donate?</strong>
                    </a>{" "}
                  </div>
                  <div
                    id="accordion16"
                    className="panel-collapse collapse"
                    role="tablist"
                    aria-expanded="false"
                  >
                    <div className="panel-content">
                      <p>
                        You can visit the Donate page mentioned at the top of
                        the page. Choose the cause and amount you wish to donate
                        to.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="panel">
                  <div className="panel-title">
                    {" "}
                    <a
                      data-parent="#accordion1"
                      data-toggle="collapse"
                      href="#accordion17"
                      className="collapsed"
                      aria-expanded="false"
                    >
                      {" "}
                      <span className="open-sub"></span>{" "}
                      <strong>Q: How can I become a volunteer?</strong>
                    </a>{" "}
                  </div>
                  <div
                    id="accordion17"
                    className="panel-collapse collapse"
                    role="tablist"
                    aria-expanded="false"
                  >
                    <div className="panel-content">
                      <p>
                        You can become a volunteer with NGO by visiting the
                        Become a Volunteer page. You will find all the necessary
                        details about being a volunteer on the page.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="panel">
                  <div className="panel-title">
                    {" "}
                    <a
                      data-parent="#accordion1"
                      data-toggle="collapse"
                      href="#accordion18"
                      className="collapsed"
                      aria-expanded="false"
                    >
                      {" "}
                      <span className="open-sub"></span>{" "}
                      <strong>
                        Q: Is there any other way to partner with NGO?
                      </strong>
                    </a>{" "}
                  </div>
                  <div
                    id="accordion18"
                    className="panel-collapse collapse"
                    role="tablist"
                    aria-expanded="false"
                  >
                    <div className="panel-content">
                      <p>
                        If you want to get involved in any other way, visit the
                        Get Involved page to know more.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="panel">
                  <div className="panel-title">
                    {" "}
                    <a
                      data-parent="#accordion1"
                      data-toggle="collapse"
                      href="#accordion19"
                      className="collapsed"
                      aria-expanded="false"
                    >
                      {" "}
                      <span className="open-sub"></span>{" "}
                      <strong>
                        Q: Do I have to give a tip on my Donation?
                      </strong>
                    </a>{" "}
                  </div>
                  <div
                    id="accordion19"
                    className="panel-collapse collapse"
                    role="tablist"
                    aria-expanded="false"
                  >
                    <div className="panel-content">
                      <p>
                        No. It’s not necessary for you to give a tip on your
                        donation. However, the tips you give will be used to
                        reach out to more causes, manage logistics, and donate
                        to different causes
                      </p>
                    </div>
                  </div>
                </div>
                <div className="panel">
                  <div className="panel-title">
                    {" "}
                    <a
                      data-parent="#accordion1"
                      data-toggle="collapse"
                      href="#accordion20"
                      className="collapsed"
                      aria-expanded="false"
                    >
                      {" "}
                      <span className="open-sub"></span>{" "}
                      <strong>Q: Do I get tax benefits on my donation?</strong>
                    </a>{" "}
                  </div>
                  <div
                    id="accordion20"
                    className="panel-collapse collapse"
                    role="tablist"
                    aria-expanded="false"
                  >
                    <div className="panel-content">
                      <p>Yes. You get tax benefits on your donation.</p>
                    </div>
                  </div>
                </div>
                <div className="panel">
                  <div className="panel-title">
                    {" "}
                    <a
                      data-parent="#accordion1"
                      data-toggle="collapse"
                      href="#accordion21"
                      className="collapsed"
                      aria-expanded="false"
                    >
                      {" "}
                      <span className="open-sub"></span>{" "}
                      <strong>
                        Q: What happens when the target is reached?
                      </strong>
                    </a>{" "}
                  </div>
                  <div
                    id="accordion21"
                    className="panel-collapse collapse"
                    role="tablist"
                    aria-expanded="false"
                  >
                    <div className="panel-content">
                      <p>
                        When a target is reached, all of the money collected
                        during the campaign is transferred to the cause.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
