import React from "react";
import { MakeDonationWrapper } from "../OurCampaigns/styles";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

export default function DonateNow() {
  return (
    <div className="container">
      <MakeDonationWrapper className="widget mt-20">
        <div className="pl-0">
          <div className="">
            <h3 className="mt-0">
              Please select the amount you wish to donate
            </h3>

            <form id="popup_paypal_donate_form_onetime_recurring">
              <div className="row">
                <div className="col-md-12">
                  {/* <img src="images/payment-card-logo-sm.png" alt="" /> */}

                  {/* <div className="form-group mt-10 mb-20">
                                            <p><label><strong>Payment Type</strong></label></p>
                                            <label className="radio-inline">
                                                <input type="radio" value="one_time" name="payment_type" readOnly/>
                                                One Time
                                            </label>                                           
                                        </div> */}

                  <div className="tabs">
                    <div className="tab-item active">Donate once</div>
                    <div className="tab-item">Donate regularly</div>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="form-group mb-20">
                    <label>
                      <strong>Choose an amount to donate?</strong>
                    </label>
                    <div className="radio mt-5">
                      <Row className="mb-15">
                        <Col md="4">
                          <label className="radio-inline">
                            <input type="radio" name="amount" value="50" />
                            Rs.500/-
                          </label>
                        </Col>
                        <Col md="4">
                          <label className="radio-inline">
                            <input type="radio" name="amount" value="100" />
                            Rs.1000/-
                          </label>
                        </Col>
                        <Col md="4">
                          <label className="radio-inline">
                            <input type="radio" name="amount" value="200" />
                            Rs.2000/-
                          </label>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12 mt-10">
                          <div id="custom_other_amount">
                            <input
                              type="number"
                              name="amount"
                              value="other"
                              placeholder="Enter other amount"
                              className="form-control"
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="form-group">
                    <Link to="/donation-form">
                      <button
                        type="submit"
                        className="btn btn-flat btn-dark btn-theme-colored mt-10 pl-30 pr-30"
                        data-loading-text="Please wait..."
                      >
                        Donate Now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </form>

            <form
              id="popup_paypal_donate_form-onetime"
              className="hidden"
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
            >
              <input type="hidden" name="cmd" value="_donations" />
              <input
                type="hidden"
                name="business"
                value="accounts@thememascot.com"
              />

              <input type="hidden" name="item_name" value="Educate Children" />
              <input type="hidden" name="currency_code" value="USD" />
              <input type="hidden" name="amount" value="20" />

              <input type="hidden" name="no_shipping" value="1" />
              <input type="hidden" name="cn" value="Comments..." />
              <input type="hidden" name="tax" value="0" />
              <input type="hidden" name="lc" value="US" />
              <input type="hidden" name="bn" value="PP-DonationsBF" />
              <input
                type="hidden"
                name="return"
                value="http://www.yoursite.com/thankyou.html"
              />
              <input
                type="hidden"
                name="cancel_return"
                value="http://www.yoursite.com/paymentcancel.html"
              />
              <input
                type="hidden"
                name="notify_url"
                value="http://www.yoursite.com/notifypayment.php"
              />
              <input type="submit" name="submit" />
            </form>

            <form
              id="popup_paypal_donate_form-onetime"
              className="hidden"
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
            >
              <input type="hidden" name="cmd" value="_donations" />
              <input
                type="hidden"
                name="business"
                value="accounts@thememascot.com"
              />

              <input type="hidden" name="item_name" value="Educate Children" />
              <input type="hidden" name="currency_code" value="USD" />
              <input type="hidden" name="amount" value="20" />

              <input type="hidden" name="no_shipping" value="1" />
              <input type="hidden" name="cn" value="Comments..." />
              <input type="hidden" name="tax" value="0" />
              <input type="hidden" name="lc" value="US" />
              <input type="hidden" name="bn" value="PP-DonationsBF" />
              <input
                type="hidden"
                name="return"
                value="http://www.yoursite.com/thankyou.html"
              />
              <input
                type="hidden"
                name="cancel_return"
                value="http://www.yoursite.com/paymentcancel.html"
              />
              <input
                type="hidden"
                name="notify_url"
                value="http://www.yoursite.com/notifypayment.php"
              />
              <input type="submit" name="submit" />
            </form>
          </div>
        </div>
      </MakeDonationWrapper>
    </div>
  );
}
