import React, { FunctionComponent, useRef } from "react";
import { Link } from "react-router-dom";
import logoImg from "../../../assets/images/logo.png";
import { SectionFooter } from "./styles";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";

const Footer: FunctionComponent = () => {
  const form = useRef<HTMLFormElement>(null); // Corrected the useRef type

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    // Corrected the type of e
    e.preventDefault();

    emailjs
      .sendForm(
        "service_wbs7pus",
        "template_nehnvk3",
        form.current as HTMLFormElement,
        "2D-ybvemeQmDGowSt"
      )
      .then(
        (result) => {
          console.log(result.text);
          Swal.fire({
            title: "Thank you for registering!",
            text: "We will get back soon.",
            icon: "success",
            confirmButtonText: "OK",
          });
        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            title: "Error!",
            text: error?.response?.data?.error?.message
              ? error?.response?.data?.error?.message
              : "Something went wrong!",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      );
    e.currentTarget.reset(); // Used currentTarget to reset the form
  };

  return (
    <SectionFooter>
      <footer className="pb-0">
        <div className="container pt-60 pb-30">
          <div className="row multi-row-clearfix m-0">
            <div className="col-sm-6 col-md-6">
              <div className="widget dark">
                <h5 className="widget-title line-bottom">Menu</h5>
                <div className="latest-posts">
                  <article className="post media-post clearfix pb-0 mb-10">
                    <div className="post-right">
                      <Link to="/home">Home</Link>
                    </div>
                  </article>
                  <article className="post media-post clearfix pb-0 mb-10">
                    <div className="post-right">
                      <Link to="/about-us">About Us</Link>
                    </div>
                  </article>
                  <article className="post media-post clearfix pb-0 mb-10">
                    <div className="post-right">
                      <Link to="/our-campaigns">Our Events</Link>
                    </div>
                  </article>

                  <article className="post media-post clearfix pb-0 mb-10">
                    <div className="post-right">
                      <Link to="/campaigngallery">Gallery</Link>
                    </div>
                  </article>
                  <article className="post media-post clearfix pb-0 mb-10">
                    <div className="post-right">
                      <Link to="/MemberRegistration">Join Us</Link>
                    </div>
                  </article>
                  <article className="post media-post clearfix pb-0 mb-10">
                    <div className="post-right">
                      <Link to="/StaticPageDonateNow">Donate</Link>
                    </div>
                  </article>
                  <article className="post media-post clearfix pb-0 mb-10">
                    <div className="post-right">
                      <Link to="/Contact">Contact</Link>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6">
              <div className="widget dark">
                <h5 className="widget-title line-bottom">Quick Contact</h5>
                <form
                  id="checkout-form"
                  ref={form}
                  onSubmit={sendEmail}
                  style={{ color: "white" }}
                >
                  <div className="row">
                    <div className=" form">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Name"
                          name="name"
                          required
                        />
                      </div>

                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter your email address"
                          name="email"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>Mobile Number</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter your mobile number"
                          name="mobile"
                          maxLength={10}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^\d]/g,
                              ""
                            ); // Remove non-numeric characters
                            if (e.target.value.length > 10) {
                              e.target.value = e.target.value.slice(0, 10); // Limit input to 10 characters
                            }
                          }}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <label>Suggestions</label>
                        <textarea
                          type="text"
                          className="form-control"
                          placeholder="Suggestions"
                          name="suggestions"
                          required
                        />
                      </div>
                      <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <div className="pLace-order mt-10">
                            <button
                              className="view-all-accent-btn btn btn-success"
                              type="submit"
                              value="Submit"
                              style={{ color: "white" }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="horizontal-contact-widget mt-30 pt-30 text-center">
                {/* <div className="col-sm-12 col-sm-4">
                  <div className="each-widget">
                    {" "}
                    <i className="pe-7s-phone font-36 mb-10"></i>
                    <h5 className="">Call Us</h5>
                    <p>
                      Phone: <a href="tel:+393756753988">+39 375 675 3988</a>
                    </p>
                  </div>
                </div> */}
                <div className="col-sm-12 col-sm-6 mt-sm-50">
                  <div className="each-widget">
                    {" "}
                    <i className="pe-7s-map font-36 mb-10"></i>
                    <h5 className="">Address</h5>
                    <p className="">
                      Corso Grosseto 295, 10151, Torino TO, Italy.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-sm-6 mt-sm-50">
                  <div className="each-widget">
                    {" "}
                    <i className="pe-7s-mail font-36 mb-10"></i>
                    <h5 className="">Email</h5>
                    <p>
                      <Link to="/" className="">
                        info@bharatiyatelugusamithiitaly.org
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-12">
              <ul className="list-inline styled-icons icon-hover-theme-colored icon-gray icon-circled text-center mt-30 mb-10">
                <li>
                  <a
                    href="www.facebook.com"
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    target={"_blank"}
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>

                <li>
                  <a
                    href="www.instagram.com"
                    // eslint-disable-next-line
                    target={"_blank"}
                  >
                    <i className="fa fa-instagram"></i>
                  </a>{" "}
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </footer>
      {/* <Link className="scrollToTop" to="/">
        <i className="fa fa-angle-up"></i>
      </Link> */}
    </SectionFooter>
  );
};

export default Footer;
