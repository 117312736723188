import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import { MemberRegistrationWrapper } from "./styles";
import ContactBannerImg from "../../assets/images/gallery/group.png";
import { RotatingLines } from "react-loader-spinner";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const MemberRegistration = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    const phoneInputValue = form.current["phone"].value.replace(/\D/g, ""); // Remove all non-digit characters
    const whatsappInputValue = form.current["whatsapp"].value.replace(
      /\D/g,
      ""
    ); // Remove all non-digit characters

    console.log("Phone input value:", phoneInputValue);
    console.log("WhatsApp input value:", whatsappInputValue);

    if (phoneInputValue.length !== 12) {
      console.log("Validation failed:", phoneInputValue.length);
      Swal.fire({
        title: "Error!",
        text: "Please enter a valid 10-digit phone number.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    if (whatsappInputValue.length !== 12) {
      console.log("Validation failed:", whatsappInputValue.length);
      Swal.fire({
        title: "Error!",
        text: "Please enter a valid 10-digit whatsApp number.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    setLoading(true);
    emailjs
      .sendForm(
        "service_wbs7pus",
        "template_zagr0ld",
        form.current,
        "2D-ybvemeQmDGowSt"
      )
      .then(
        (result) => {
          console.log(result.text);
          Swal.fire({
            title: "Thank you for registering!",
            text: "We will get back to you soon.",
            icon: "success",
            confirmButtonText: "OK",
          });
        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            title: "Error!",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      )
      .finally(() => {
        setLoading(false);
        e.target.reset();
      });
  };

  return (
    <MemberRegistrationWrapper className="mt-30 mb-50">
      {/* <div className="col-sm-12 banner mb-50">
        <img src={ContactBannerImg} alt="" className="banner-img" />
      </div> */}

      <div className="container">
        <h3 className="row line-bottom border-bottom ">Member Registration</h3>
        <div className="row">
          <div className="widget">
            <div className="member-registration-form">
              {loading ? (
                <div className="loader-container d-flex align-items-center justify-content-center pt-50 pb-50">
                  <RotatingLines
                    visible={true}
                    width="96"
                    strokeWidth="5"
                    animationDuration="0.75"
                  />
                </div>
              ) : (
                <form ref={form} onSubmit={sendEmail}>
                  <div className="col-12">
                    <h4 className="mb-50">
                      Please fill out the following registration form to get
                      started in our journey.
                    </h4>
                  </div>
                  <label htmlFor="firstName">
                    First Name <b className="asterik">*</b>
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      placeholder="First Name"
                      required
                    />
                  </label>

                  <label htmlFor="lastName">
                    Last Name <b className="asterik">*</b>
                    <input
                      type="text"
                      name="lastName"
                      className="form-control"
                      placeholder="Last Name"
                      required
                    />
                  </label>

                  <label htmlFor="email">
                    Email <b className="asterik">*</b>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      required
                    />
                  </label>

                  <label htmlFor="phone">
                    Phone <b className="asterik">*</b>
                    <PhoneInput
                      onlyCountries={["it", "in"]}
                      country={"it"}
                      inputProps={{
                        name: "phone",
                        required: true,
                        autoFocus: true,
                        placeholder: "Phone",
                      }}
                      containerClass="phone-input-container"
                      inputClass="phone-input"
                    />
                  </label>

                  <label htmlFor="whatsapp">
                    WhatsApp Number <b className="asterik">*</b>
                    <PhoneInput
                      onlyCountries={["it", "in"]}
                      country={"in"}
                      inputProps={{
                        name: "whatsapp",
                        placeholder: "WhatsApp Number",
                        required: true,
                      }}
                      containerClass="whatsapp-input-container"
                      inputClass="whatsapp-input"
                    />
                  </label>

                  <label htmlFor="nativeLanguage">
                    Native Language / Mother Tongue <b className="asterik">*</b>
                    <input
                      type="text"
                      name="nativeLanguage"
                      className="form-control"
                      placeholder="Native Language"
                      required
                    />
                  </label>

                  <label htmlFor="gender">
                    Gender <b className="asterik">*</b>
                    <select name="gender" className="form-control" required>
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </label>

                  <label htmlFor="ssn">
                    Codice Fiscale
                    <input
                      type="text"
                      name="ssn"
                      className="form-control"
                      placeholder="Codice Fiscale"
                    />
                  </label>

                  <button
                    className="btn btn-flat btn-dark btn-theme-colored mt-10 pl-30 pr-30"
                    type="submit"
                  >
                    Submit
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </MemberRegistrationWrapper>
  );
};

export default MemberRegistration;
