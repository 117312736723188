import React from "react";
import { Link } from "react-router-dom";
import OurCampaigns from "../OurCampaigns";

export default function CampaignWithus() {
  return (
    <div className="main-content">
      <section className="bg-lighter">
        <div className="container pt-70 pt-sm-30">
          <div className="section-content">
            <div className="row">
              <div className="col-md-7 mb-sm-40">
                <h3 className="pb-10 mt-0">Campaign</h3>
                <p>
                  There are many causes in the country that need attention and
                  support. Some of them are related to physical disabilities and
                  medical issues and some are caused by poverty, abandonment,
                  natural calamities or other kinds of misfortune. NGO allows
                  non-profit organisations to list their campaigns and start a
                  fundraiser for it.{" "}
                </p>
              </div>
              <div className="col-md-5">
                <div className="owl-carousel-1col" data-nav="true">
                  <div className="item">
                    <img
                      src="https://i.pinimg.com/originals/3d/43/21/3d4321adf7b22ac83eeff9375c84b6f3.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container pb-40">
          <div className="section-content">
            <div className="row">
              <div className="col-md-12">
                <h2 className="mt-0">Current Campaigns</h2>
                <p>
                  Mentioned below are the campaigns that are currently active.
                  You can donate to one or more causes.
                </p>
              </div>
            </div>
            <div className="row mt-30">
              <OurCampaigns />
            </div>
          </div>
        </div>
      </section>

      <section className="divider layer-overlay overlay-theme-colored">
        <div className="container pt-0 pb-0">
          <div className="row">
            <div className="call-to-action sm-text-center">
              <div className="col-md-9">
                <h2 className="text-white font-opensans font-30 mt-0 mb-5">
                  Please raise your hand
                </h2>
                <h3 className="text-white font-opensans font-18 mt-0">
                  for those helpless childrens who need it
                </h3>
              </div>
              <div className="col-md-3 mt-30">
                <Link
                  to="/donate-now"
                  className="btn btn-default btn-circled btn-lg"
                >
                  Become a Fundraiser
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
