import React, { useState } from "react";
import volunteers1 from "../../assets/images/volunteers1.jpg";

export default function Volunteers() {
  const [volunteers, setVolunteers] = useState([
    {
      id: 1,
      src: volunteers1,
      name: "Guru Subramanyam Kanakadandi",
      position: "PRESIDENT",
      description:
        " Guru Subramanyam Kanakadandi, a proud native of Kadapa in Andhra Pradesh. Completed Masters in Automotive Engineering from Politecnico di Torino and currently contributes expertise as an automotive engineer at Joynext. Beyond my professional pursuit, I'm committed to work for the community’s betterment.",
      showFullDescription: false,
    },
    {
      id: 2,
      src: volunteers1,
      name: "Nageswar Rao Venthrumalli",
      position: "VICE PRESIDENT",
      description: "",
    },
    {
      id: 3,
      src: volunteers1,
      name: "Mohammed Musthaq",
      position: "GENERAL SECRETARY",
      description:
        "Mushtaq Mohammed, native of Hyderabad, Telangana and living in Torino, Italy from 2017. Completed MSc in Automotive from POLITO and currently working as an ADAS Test Strategy and Planning engineer with Stellantis Europe. Hailing from Hyderabad I bring in a blend of tradition and modernity to our community. Being a part of this incredible community that celebrates the essence of Telugu culture. As someone deeply passionate about our rich heritage and looking forward to engaging with you all, sharing insights, and contributing to the collective spirit of this association.",
      showFullDescription: false,
    },
  ]);

  // Function to toggle between full and truncated descriptions
  const toggleDescription = (index: any) => {
    const updatedVolunteers = [...volunteers];
    updatedVolunteers[index].showFullDescription =
      !updatedVolunteers[index].showFullDescription;
    setVolunteers(updatedVolunteers);
  };

  return (
    <div className="main-content">
      <section>
        <div className="container pt-70 pb-40">
          <div className="section-content">
            <div className="row">
              {volunteers.map((item) => (
                <div key={item.id} className="col-sm-6 col-md-3 col-lg-3 mb-30">
                  <div className="team-members maxwidth400">
                    <div className="team-thumb">
                      <img src={item.src} alt="" className="img-fullwidth" />
                    </div>
                    <div className="team-bottom-part bg-silver-light border-bottom-theme-color-2px p-20">
                      <h4 className="text-uppercase m-0 pb-5">{item.name}</h4>
                      <h6 className="mt-0 font-weight-600 font-13 text-gray-darkgray">
                        - {item.position}
                      </h6>
                      <p>
                        {item.showFullDescription
                          ? item.description
                          : `${item.description.slice(0, 150)}...`}
                        {item.description.length > 150 && (
                          <span
                            className="text-primary cursor-pointer"
                            onClick={() => toggleDescription(item.id - 1)}
                          >
                            {item.showFullDescription
                              ? " Read Less"
                              : " Read More"}
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
