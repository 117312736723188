import React, { useRef } from "react";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import { SectionRegistration } from "./styles";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_wbs7pus",
        "template_nehnvk3",
        form.current,
        "2D-ybvemeQmDGowSt"
      )
      .then(
        (result) => {
          console.log(result.text);
          Swal.fire({
            title: "Thank you for registering!",
            text: "We will get back soon.",
            icon: "success",
            confirmButtonText: "OK",
          });
        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            title: "Error!",
            text: error?.response?.data?.error?.message
              ? error?.response?.data?.error?.message
              : "Something went wrong!",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      );
    e.target.reset();
  };

  return (
    <>
      <SectionRegistration>
        <div className="registration-details-area inner-page-padding container">
          <form id="checkout-form" ref={form} onSubmit={sendEmail}>
            <div className="row">
              <div className="col-sm-5 form">
                <h2 className="sidebar-title mb-30">
                  Please fill the form and Submit
                </h2>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    name="name"
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your email address"
                    name="email"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Mobile Number</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter your mobile number"
                    name="mobile"
                    maxLength={10}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^\d]/g, ""); // Remove non-numeric characters
                      if (e.target.value.length > 10) {
                        e.target.value = e.target.value.slice(0, 10); // Limit input to 10 characters
                      }
                    }}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Suggestions</label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Suggestions"
                    name="suggestions"
                    required
                  />
                </div>

                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div className="pLace-order mt-20">
                      <button
                        className="btn btn-flat btn-dark btn-theme-colored w-100"
                        type="submit"
                        value="Submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-1"></div>
              <div className="col-sm-6">
                <h2> Bharatiya Telugu Samithi in Torino</h2>
                <p>
                  As an NGO dedicated to fostering cultural heritage and
                  community engagement, we specialize in organizing vibrant
                  events to bring people together in the spirit of unity and
                  joy.
                </p>
                <p className="mt-30">
                  <b>Our Address: </b>
                  Corso Grosseto 295, 10151 Torino TO, Italy.
                </p>
                <p className="mt-10">
                  <b>Email:</b> info@bharatiyatelugusamithiitaly.org
                </p>
                {/* <p className="mt-30">
                  <b>Contact:</b>{" "}
                  <a href="tel:+393756753988">+39 375 675 3988</a>
                </p> */}
              </div>
            </div>
          </form>
        </div>
      </SectionRegistration>
    </>
  );
};

export default Contact;
