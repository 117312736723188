import React from "react";
import AboutUsImg from "../../assets/images/aboutus.svg";
import { DonationNowWrapper } from "../styles";

export default function StaticPageDonateNow() {
  return (
    <div className="main-content">
      <DonationNowWrapper className="mt-50 mb-50">
        <div className="container">
          <h3 className="line-bottom border-bottom mt-0 mt-sm-20">
            Donate Now
          </h3>
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <h3>
                <b>For Donation:</b>
              </h3>
              <section className="icon-box">
                <p className="mb-10">
                  Account Name: <b>Bharatiya Telugu Samithi in Torino</b>
                </p>
                <p className="mb-10">
                  IBAN: <b>IT65T0538701002000004052134</b>
                </p>
                <p>
                  BIC (Swift Code): <b>BPMOIT22XXX</b>
                </p>
              </section>
            </div>
            <div className="col-xs-12 col-md-6">
              <img
                src={AboutUsImg}
                className="img-fullwidth"
                alt=""
                style={{ height: 300, objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </DonationNowWrapper>
    </div>
  );
}
