import styled from "styled-components";

export const HeaderWrapper = styled.div`
  background: white;
  box-shadow: 0 0 10px #eee;

  nav {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;

    .left {
      @media (max-width: 768px) {
        width: 100%;
      }

      i {
        display: none;
      }

      @media (max-width: 768px) {
        i {
          display: block;
          color: #6e6161;
        }
      }
    }
  }

  .active-tab {
    background-color: ${(props: any) => props.theme.primary.THEME};
    color: white;
    display: flex;
    align-items: center;
  }

  .logo-wrapper {
    .logo {
      width: 80px;
    }

    p {
      color: #be2929;
      line-height: 20px;
      font-size: 14px;
      font-weight: 500;
      padding-left: 12px;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }

  ul {
    display: flex;
    align-items: center;

    li {
      padding: 6px 20px;

      a {
        color: #333333;
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;

        &:hover {
          color: #f0452e;
        }
      }
    }

    .active {
      background-color: #f0452e;
      border-radius: 20px;

      a {
        color: white;

        &:hover {
          color: white;
        }
      }
    }
  }
`;

export const MobileMenuWrapper = styled.div`
  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    width: 70%;
    height: 100%;
    left: 0;
    background-color: white;
    z-index: 99;
    box-shadow: 0 0 10px #ccc;

    .menu-close {
      display: block;
      display: flex;
      justify-content: flex-end;
      padding: 12px;
    }

    ul {
      margin: 20px;
      padding: 0;
      margin-top: 10px;
      display: flex;
      flex-direction: column;

      li {
        padding: 10px 20px;
        margin: 6px 0;

        a {
          color: #333333;
          font-size: 14px;
          font-weight: 600;
          text-decoration: none;

          &:hover {
            color: #f0452e;
          }
        }
      }

      .active {
        background-color: #f0452e;
        border-radius: 20px;

        a {
          color: white;

          &:hover {
            color: white;
          }
        }
      }
    }
  }
`;
