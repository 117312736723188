import React, { useState, useEffect } from "react";
import axios from "axios";
import MainSlider from "./MainSlider";
import { API_URL } from "../../constants";
import { MainContainer } from "./styles";
import PositiveChangeImg from "../../assets/images/positive-change.svg";
import VounteerImg from "../../assets/images/smiley-volunteers-posing-while-giving-thumbs-up.jpg";
import { Link } from "react-router-dom";
import Loading from "../../assets/images/loading-new.gif";
import { IndividualCampaign } from "../OurCampaigns/styles";
import GenericCauses from "../GenericComponents/GenericCause";
import Swal from "sweetalert2";

export default function Home() {
  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getCampaignsData = () => {
    setIsLoading(true);
    axios
      .get(`${API_URL}/campaigns/`)
      .then((response) => {
        // Filter ongoing and upcoming events
        const currentDate = new Date();
        const ongoingCampaigns = response.data.data.filter(
          (campaign: any) => new Date(campaign.EndDate) > currentDate
        );

        setCampaigns(ongoingCampaigns);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        Swal.fire({
          title: "Error!",
          text: error?.response?.data?.error?.message
            ? error?.response?.data?.error?.message
            : "Something went wrong! Please reload the web page.",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  useEffect(() => {
    getCampaignsData();
  }, []);

  return (
    <MainContainer>
      <MainSlider />

      <section className="bg-white pt-40">
        <div className="container pb-40">
          <div className="section-title text-center">
            <h2 className="mt-0">
              Our{" "}
              <span className="text-theme-colored font-weight-700">
                Mission
              </span>
            </h2>
          </div>
          <p className="mt-30">
            Bharatiya Telugu Samithi in Torino encourages people to host major
            cultural and social events thus forming a better platform for
            structural representation among local communities and authorities.
          </p>
          <p className="mt-20">
            Bharatiya Telugu Samithi  is driven by the commitment to showcase
            our vibrant India’s spirit to share info and extend the needful
            support and it is a non- profit organization, non- governmental and
            doesn't promote any kind of discrimination.
          </p>

          <p className="mt-20">
            At Bharatiya Telugu Samithi, we believe in the power of celebration
            to inspire, educate, and connect communities. Join us as we continue
            our journey of spreading joy, promoting cultural heritage, and
            building meaningful connections through our diverse range of events
            and initiatives.
          </p>
        </div>
      </section>

      <section className="bg-light pt-40">
        <div className="container pb-40">
          <div className="section-title text-center">
            <h2 className="mt-0">
              Upcoming{" "}
              <span className="text-theme-colored font-weight-700">Events</span>
            </h2>
          </div>
          <div className="multi-row-clearfix row">
            {isLoading ? (
              <div className="custom-loading mt-20 mb-20">
                {[...Array(4)].map((_, index) => (
                  <img
                    key={index}
                    className="floating col-sm-3"
                    src={Loading}
                    alt=""
                  />
                ))}
              </div>
            ) : (
              <div className="mt-20 p-0 d-flex flex-wrap">
                {campaigns?.length === 0 ? (
                  <div className="no-results alert alert-warning">
                    <p>
                      {" "}
                      At this time, there are no upcoming events scheduled. We
                      appreciate your interest and encourage you to check back
                      soon for updates. Our team is constantly working on
                      planning new and exciting events that align with our
                      mission and community interests. In the meantime, we
                      invite you to explore our website and learn more about our
                      ongoing projects, past events, and ways you can get
                      involved.
                    </p>
                  </div>
                ) : (
                  campaigns.map((item: any, key: any) => (
                    <IndividualCampaign key={key} className="col-sm-12">
                      <GenericCauses
                        categoryName={item?.CategoryName}
                        banner={item.BannerImage}
                        title={item.Title}
                        TagLine={item.TagLine}
                        description={item.Description}
                        raisedAmount={item.RaisedAmount || 0}
                        percent={Math.round(
                          ((item.RaisedAmount || 0) / (item.GoalAmount || 1)) *
                            100
                        )}
                        id={item.id}
                        isDonorsCount={item.DonorsCount}
                        isStartDate={item.StartDate}
                      />
                    </IndividualCampaign>
                  ))
                )}
              </div>
            )}
          </div>
        </div>
      </section>

      <section className="divider parallax  overlay-dark pb-50  become-volunteer-section">
        <div className="container">
          <div className="row d-flex align-items-center flex-wrap">
            <div className="col-md-5 pt-50 d-xs-none">
              <img src={VounteerImg} alt="" className="volunteer-banner" />
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-6">
              <h3>Join Us as a Member</h3>

              <p>
                We are welcoming passionate individuals to become an integral
                part of the journey. Together Our enthusiasm and dedication can
                spread happiness to the community.
              </p>
              <Link
                to="/MemberRegistration"
                className="btn btn-lg btn-success btn-circled  mt-20"
              >
                Join Now
              </Link>
              <div className="col-md-6 space pt-50 d-sm-none">
                <img src={VounteerImg} alt="" className="volunteer-banner" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light divider parallax overlay-dark pb-50 become-volunteer-section">
        <div className="container">
          <div className="row d-flex align-items-center flex-wrap">
            <div className="col-md-6">
              <h2>
                Our Diverse
                <span className="text-theme-colored"> Initiatives</span>
              </h2>
              <ul>
                <li style={{ fontSize: 22 }}>
                  As an NGO dedicated to fostering cultural heritage and
                  community engagement, we specialize in organizing vibrant
                  events to bring people together in the spirit of unity and
                  joy.
                </li>
              </ul>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-4">
              <img
                src={PositiveChangeImg}
                alt=""
                className="volunteer-banner"
              />
            </div>
          </div>
        </div>
      </section>
    </MainContainer>
  );
}
