import React, { useState, useEffect } from "react";
import { API_URL } from "../../constants";
import { CustomCarousel } from "./styles";
import axios from "axios";
import Slider from "react-animated-slider";

export default function MainSlider() {
  const [banners, setBanners] = useState([]);

  // const banners = [{ src: banner1 }];
  const fetchBanners = () => {
    axios
      .get(
        `${API_URL}/banners?filter=%7B%0A%20%20%22where%22%3A%20%7B%22status%22%3A%20true%0A%20%20%7D%0A%7D`
      )
      .then((response) => {
        setBanners(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  return (
    <>
      <CustomCarousel>
        <Slider className="slider-wrapper">
          {banners.map((item, index) => (
            <div
              key={index}
              className="slider-content"
              style={{
                background: `url('${item.ImageUrl}')`,
              }}
            >
              <div className="inner">
                <h1>{item.Title}</h1>
              </div>
            </div>
          ))}
        </Slider>
        {/* <Carousel
          className="fullwidth-carousel"
          autoPlay={true}
          transitionTime={1}
          infiniteLoop={true}
          emulateTouch={false}
          showIndicators={true}
          swipeable={false}
        >
          {banners.map(function (item) {
            return (
              <div
                className="item bg-img-cover"
                style={{
                  position: "relative",
                  height: "680px",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    backgroundColor: "rgb(0 0 0 / 30%)",
                  }}
                ></div>
                <div
                  className="item bg-img-cover slide-background"
                  style={{
                    backgroundImage: `url(${item.ImageUrl})`,
                  }}
                >
                  <div className="display-table">
                    <div className="display-table-cell">
                      <div className="container pt-150 pb-150">
                        <div className="row">
                          <div className="col-md-8 col-md-offset-2 text-center">
                            <div
                              className="inline-block mt-40 pb-60 pl-60 pr-60 pt-40"
                              style={{ backgroundColor: "transparent" }}
                            >
                              <h2 className="text-white font-raleway letter-spacing-2 mb-20 description">
                                {item.Title}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                |
              </div>
            );
          })}
        </Carousel> */}
      </CustomCarousel>

      {/* <section>
        <div className="container pt-50 pb-0">
          <div className="section-content text-center">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-4">
                <div className="icon-box iconbox-theme-colored bg-white p-15 pb-20 border-1px">
                  <img src={Img1} alt="" />

                  <h3 className="text-uppercase mt-30 mb-15">
                    Dance Performances
                  </h3>
                  <p className="text-black">
                    Our team of skilled dancers is committed to making your
                    event unforgettable.
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <div className="icon-box iconbox-theme-colored bg-white p-15 pb-20 border-1px">
                  <img src={Img2} alt="" />

                  <h3 className="text-uppercase mt-30 mb-15">Drive Change</h3>
                  <p className="text-black">
                    We extend a warm welcome to individuals ready to make a
                    meaningful difference.
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <div className="icon-box iconbox-theme-colored bg-white p-15 pb-20 border-1px">
                  <img src={Img3} alt="" />

                  <h3 className="text-uppercase mt-30 mb-15">
                    Our Divine Celebrations
                  </h3>
                  <p className="text-black">
                    Organizing vibrant celebrations of Ganesh Chaturthi, Sri
                    Rama Navami, and various divine events
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}
