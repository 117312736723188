import styled from "styled-components";
import {
  fontSize_BASE,
  fontSize_XXL,
  fontSize_XXXL,
  fontSize_XL,
} from "../../customFonts";

export const commonPadding = "16px 20px";
const boxHeight = "300px";

export const CauseBox = styled.div`
  box-shadow: 0 0 10px #eee;
  cursor: pointer;
  border-radius: 15px;
  background-color: white;
  height: ${boxHeight};

  @media (max-width: 480px) {
    height: auto;
  }

  .category-mark {
    p {
      margin: 0;
      text-transform: uppercase;
      font-size: 14px;
      -webkit-letter-spacing: 0.8px;
      -moz-letter-spacing: 0.8px;
      -ms-letter-spacing: 0.8px;
      letter-spacing: 0.8px;
      color: black;
      padding: 2px 4px;
      border-radius: 6px;
      font-weight: 600;
      text-align: start;
      font-size: 12px !important;
    }

    .upcoming-label {
      color: green;
    }

    .completed-label {
      color: red;
    }
  }

  .thumb {
    @media (max-width: 480px) {
      width: 100%;
    }

    img {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      height: ${boxHeight};
      width: 100%;
      object-fit: cover;
      background-position: top;

      @media (max-width: 480px) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }
    }
  }

  .description {
    text-overflow: ellipsis;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    height: 6rem;
  }

  .causes-details {
    .primary-row {
      /* border-bottom: solid 1px #eee; */
    }

    .primary-row,
    .secondary-row {
      padding: ${commonPadding};
    }

    .secondary-row {
      p {
        ${fontSize_BASE};
      }

      b {
        color: ${(props: any) => props.theme.primary.THEME};
      }

      .amount {
        p {
          color: red;
          ${fontSize_XXXL};
          margin-bottom: 0;
          font-weight: 600;
        }

        span {
          ${fontSize_XL};
          padding-left: 5px;
        }

        .right {
          p {
            color: black;
          }
        }
      }
    }

    .donor-count {
      img {
        width: 12px;
        height: 12px;
        margin-right: 5px;
        opacity: 0.5;
      }

      p {
        margin-bottom: 0;
      }
    }

    h4,
    p {
      text-overflow: ellipsis;
      white-space: pre-wrap;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      margin-bottom: 20px;
    }

    p {
      font-size: 16px !important;
    }

    .logo-img-cause {
      width: 20px;
      margin-right: 7px;
    }

    p,
    span {
      ${fontSize_BASE};
    }
  }

  .rupee-img {
    width: 2rem;
  }

  ul {
    li {
      display: flex;
      justify-content: center;
      align-items: center;

      b {
        font-weight: bolder;
        font-size: 2rem;
        position: relative;
        top: 3px;
        color: #524e4e;
      }

      small {
        font-size: 1.5rem;
      }
    }
  }

  .social-row {
    display: flex;
    justify-content: space-between;
    width: 100%;

    a {
      background-color: green;
      width: 100%;
      color: white;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.8px;
      ${fontSize_XXL};
      margin-top: 10px;
    }
  }
`;
